import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { iif, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { ErrorHook, ErrorInterface, HookEvent, HookHandlerAbstract, switchTap } from '@lobos/library';

type type = ErrorHook;

@Injectable({
  providedIn: 'root',
})
export class ErrorShowToastHandler extends HookHandlerAbstract<type, Observable<ErrorInterface>> {
  constructor(private transloco: TranslocoService, private toastr: ToastrService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'ErrorHook' }];
  }

  override after(event: HookEvent<type, Observable<ErrorInterface>>, result: Observable<ErrorInterface>): Observable<ErrorInterface> {
    return super.after(event, result).pipe(
      switchTap((error: ErrorInterface) =>
        iif(
          () => !!error.display,
          of(error).pipe(
            map(() => (error.error.error || error.error)[error.display === true ? 'message' : (error.display as keyof ErrorInterface)]),
            switchMap((rawMessage: string) =>
              iif(
                () => error.translate!,
                this.transloco.selectTranslate(rawMessage, undefined, error.translateScope).pipe(first()),
                of(rawMessage),
              ),
            ),
            tap((message: string) => this.toastr.error(message)),
          ),
          of(error),
        ),
      ),
    );
  }
}
