import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoginDialogService } from '../../services/login/login-dialog.service';
import { AuthService } from '@lobos/library';

@Component({
  selector: 'app-cart-type-mismatch-modal',
  templateUrl: './cart-type-mismatch-modal.component.html',
  styleUrls: ['./cart-type-mismatch-modal.component.scss'],
})
export class CartTypeMismatchModalComponent {
  isLoggedIn$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<CartTypeMismatchModalComponent>,
    private loginDialogService: LoginDialogService,
    private authService: AuthService,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
  }

  create() {
    this.dialogRef.close({ create: true });
  }

  cancel() {
    this.dialogRef.close();
  }

  openLoginDialog() {
    this.dialogRef.close();
    this.loginDialogService.open();
  }
}
