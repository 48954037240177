import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { FavoriteItem, HookEvent, HookHandlerAbstract, switchTap, WorkflowCreateHook } from '@lobos/library';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

type type = WorkflowCreateHook<'CreateFavoriteItemHook'>;

@Injectable({
  providedIn: 'root',
})
export class FavoriteCreateItemHandler extends HookHandlerAbstract<type, Observable<FavoriteItem>> {
  private static readonly TRANSLOCO_SCOPE = 'profile';

  constructor(private toastr: ToastrService, private translocoService: TranslocoService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateFavoriteItemHook' }];
  }

  override after(event: HookEvent<type, Observable<FavoriteItem>>, result: Observable<FavoriteItem>): Observable<FavoriteItem> {
    return super.after(event, result).pipe(
      switchTap((_: FavoriteItem) => {
        return this.translocoService
          .selectTranslate('favorites.favorite-service.toast-success-add', undefined, FavoriteCreateItemHandler.TRANSLOCO_SCOPE)
          .pipe(
            first(),
            tap((message: string) => this.toastr.success(message)),
          );
      }),
    );
  }
}
