import {
  Cart,
  CartHeaderLocalQuery,
  CartHeaderLocalStore,
  CartItemLocalQuery,
  CartItemLocalStore,
  CartLocalService,
  SteelCreateCartItemInterface,
} from '@lobos/library';
import { Observable, of } from 'rxjs';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { WeylandCartAdapterInterface } from '../../interfaces/cart-adapter.interface';
import { WeylandSteelCartItem } from '../../interfaces/cart-item.model';
import { WeylandCartHeader } from '../../interfaces/cart-header.model';

@Injectable({ providedIn: 'root' })
export class WeylandCartLocalService
  extends CartLocalService<WeylandCartHeader, WeylandSteelCartItem, SteelCreateCartItemInterface>
  implements WeylandCartAdapterInterface
{
  constructor(
    cartItemLocalQuery: CartItemLocalQuery<WeylandSteelCartItem>,
    cartItemLocalStore: CartItemLocalStore<WeylandSteelCartItem>,
    cartHeaderLocalQuery: CartHeaderLocalQuery<WeylandCartHeader>,
    cartHeaderLocalStore: CartHeaderLocalStore<WeylandCartHeader>,
    @Inject(LOCALE_ID) locale: string,
  ) {
    super(cartItemLocalQuery, cartItemLocalStore, cartHeaderLocalQuery, cartHeaderLocalStore, locale);
  }

  getCartTypeId(_: WeylandCartHeader): Observable<number> {
    const items: WeylandSteelCartItem[] = this.cartItemLocalQuery.getAll();
    if (!items.length) {
      return of(0);
    }

    return of(items[0].oArticle.shtPL1P48ProductTypeID);
  }

  override updateCartHeader(cartHeader: WeylandCartHeader): Observable<Cart<WeylandCartHeader, WeylandSteelCartItem> | undefined> {
    return of({ oSalesMaster: cartHeader, oSalesItemList: [] });
  }
}
