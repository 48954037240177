import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/login-dialog/login-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LoginDialogService {
  constructor(private dialog: MatDialog) {}

  public open(): MatDialogRef<any> {
    return this.dialog.open(LoginDialogComponent);
  }
}
