import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BlogPost, CmsUiDynamicContentPage, CmsUiDynamicContentService } from '@lobos/library';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WeylandCmsService extends CmsUiDynamicContentService {
  override getCMSPage(id: number): Observable<CmsUiDynamicContentPage> {
    const selector = `/cms/pages/${id}`;

    return this.http.get<CmsUiDynamicContentPage>(`${this.config.apiUrl}/pages/${id}`).pipe(
      tap((response: CmsUiDynamicContentPage) => {
        this.store.update({ [selector]: response });
      }),
    );
  }

  override getBlogPost(id: number): Observable<BlogPost> {
    const selector = `/cms/posts/${id}`;

    return this.http.get<BlogPost>(`${this.config.apiUrl}/posts/${id}`).pipe(
      tap((response: BlogPost) => {
        this.store.update({ [selector]: response });
      }),
    );
  }
}
