import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Cart,
  CartService,
  HookEvent,
  HookHandlerAbstract,
  SteelCartItem,
  SteelCreateCartItemInterface,
  switchTap,
  WorkflowDeleteHook,
} from '@lobos/library';
import { WeylandCartHeader } from 'src/app/interfaces/cart-header.model';

type type = WorkflowDeleteHook<'DeleteCartItemHook'>;

@Injectable({
  providedIn: 'root',
})
export class CartDeleteCartItemResetCartTypeHandler extends HookHandlerAbstract<type, Observable<Cart<WeylandCartHeader, SteelCartItem>>> {
  constructor(private cartService: CartService<WeylandCartHeader, SteelCartItem, SteelCreateCartItemInterface>) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowDeleteHook', entity: 'DeleteCartItemHook' }];
  }

  override after(
    event: HookEvent<type, Observable<Cart<WeylandCartHeader, SteelCartItem>>>,
    result: Observable<Cart<WeylandCartHeader, SteelCartItem>>,
  ): Observable<Cart<WeylandCartHeader, SteelCartItem>> {
    return super.after(event, result).pipe(
      switchTap((cart: Cart<WeylandCartHeader, SteelCartItem>) => {
        if (cart.oSalesItemList.length === 0) {
          return this.cartService.updateCartHeader({ ...cart.oSalesMaster, shtPL1P48ProductTypeID: 0 });
        }
        return of(undefined);
      }),
    );
  }
}
