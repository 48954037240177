import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SteelCartItem } from '@lobos/library';

@Component({
  selector: 'app-cart-detail-configuration-info',
  templateUrl: './cart-detail-configuration-info.component.html',
  styleUrls: ['./cart-detail-configuration-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CartDetailConfigurationInfoComponent {
  @Input() item!: SteelCartItem;
}
