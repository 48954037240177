import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() config: PaginationInstance | undefined;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPageChange = new EventEmitter<any>();

  constructor(private scroller: ViewportScroller) {}

  pageChangeHandler(value: any) {
    this.onPageChange.emit(value);
  }

  backToTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }
}
