<!--suppress TypeScriptUnresolvedVariable -->
<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, activeCart: activeCart$ | async } as context">
    <ng-container *ngIf="activeCartItems$ | async as items">
      <div class="header-dropdown" id="header-dropdown-cart">
        <div class="header-dropdown-label">
          <div class="icon-wrapper">
            <i class="icon-cart"></i>
            <span class="badge">{{ items.length }}</span>
          </div>
        </div>

        <div class="header-dropdown-content">
          <div class="header-dropdown-content-mobile-header">
            <i class="header-dropdown-content-mobile-header-icon icon-cart"></i>
            {{ t("core.header-nav.cart-menu.cart") }}
          </div>
          <div class="mini-cart">
            <div class="mini-cart-item mini-cart-select" [hidden]="(isLoggedIn$ | async) === false">
              <div class="flex flex--jc-space-between flex--ai-center">
                <mat-form-field>
                  <select matNativeControl [ngModel]="context.activeCart" (ngModelChange)="setActiveCart($event)">
                    <option disabled>{{ t("core.header-nav.cart-menu.choose-cart") }}</option>
                    <option *ngFor="let cart of carts$ | async" [ngValue]="cart">
                      {{ t("core.header-nav.cart-menu.cart") }} «{{ cart?.sCartName }}»
                    </option>
                  </select>
                </mat-form-field>
              </div>
            </div>
            <div class="mini-cart-item default-font-sizes linked" *ngIf="isLoggedIn$ | async">
              <a [routerLink]="'/profile/carts' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.cart-menu.manage-carts") }}</span
                ><i class="icon-setting"></i>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked" *ngIf="isLoggedIn$ | async">
              <a [routerLink]="'/profile/carts/new' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.cart-menu.new-cart") }}</span
                ><i class="icon-plus"></i>
              </a>
            </div>

            <div
              *ngFor="let item of items; trackBy: identifyCartItem"
              class="mini-cart-item"
              [ngClass]="{ 'routing-detail-highlight': !!item.shtProcessedItem || item.oArticle.oProductInfo.length === 0 }"
            >
              <div class="card-fav-item">
                <div class="card-inner">
                  <div class="card-image">
                    <app-image [image]="item.oArticle | articleMainImage"></app-image>
                  </div>
                  <div class="card-content">
                    <div class="card-content-head">
                      <div class="card-content-head-inner">
                        <p class="article-name">
                          <ng-container *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0; else elseBlock">
                            <a [routerLink]="['/article/' + item.sArticleID | localizeUrlPath]"
                              ><strong>{{ item.sArticleName }}</strong>
                            </a>
                          </ng-container>
                          <ng-template #elseBlock>
                            <strong>{{ item.sArticleName }}</strong>
                          </ng-template>
                        </p>
                        <p class="article-number">
                          {{ t("core.header-nav.cart-menu.article-number") }}: <strong>{{ item.sArticleID }}</strong>
                        </p>
                        <br />
                        <ul class="separated-list" *ngIf="item.sBO3Title1 || item.sBO3Title2 || item.sBO3Title3">
                          <li *ngIf="item.sBO3Title1">{{ item.decBO3Factor1 + " " + item.sBO3Title1 }}</li>
                          <li *ngIf="item.sBO3Title2">{{ item.decBO3Factor2 + " " + item.sBO3Title2 }}</li>
                          <li *ngIf="item.sBO3Title3">{{ item.decBO3Factor3 + " " + item.sBO3Title3 }}</li>
                        </ul>
                        <app-cart-detail-configuration-info [item]="item"></app-cart-detail-configuration-info>
                      </div>
                      <div class="card-content-head-input">
                        <app-quantity-input
                          *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0"
                          [quantity]="item.decBO3QuantityInput ? item.decBO3QuantityInput : item.decQuantityOrdered"
                          (onQuantityChange)="onQuantityChange($event, item)"
                          [isLoading]="quantityChangeLoading"
                        >
                        </app-quantity-input>
                      </div>
                    </div>
                    <div class="card-content-body">
                      <div class="card-content-body-price">
                        <strong>{{ item.decItemNetAmountFC | translocoCurrency }}</strong
                        ><span
                          >&nbsp;({{ item.decPriceNetExDiscounts | translocoCurrency }}
                          {{
                            t("shared.ui-article-price.quantityUnitText", {
                              priceUnit: item.oArticle.lngSalesPriceUnit,
                              quantityUnit: item.oArticle.sQuantityUnitSales
                            })
                          }})</span
                        >
                      </div>
                      <div class="card-content-body-cta">
                        <a
                          class="deleteArticle"
                          *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0"
                          (click)="openDeleteArticleFromCart(item)"
                        >
                          <i *ngIf="!loading" class="icon-trash"></i>
                          <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mini-cart-item" *ngIf="context.activeCart as cart">
              <div class="mini-cart-item-justify flex--ai-end summary">
                <span>
                  <ng-container *ngIf="items.length > 0; else noArticles">
                    {{ "core.header-nav.total-plurality" | transloco : { totalArticleVar: items.length } }}
                  </ng-container>
                  <ng-template class="default-font-sizes margin--top-l margin--bottom-l" #noArticles>
                    <div class="mini-cart-item default-font-sizes linked">{{ t("core.header-nav.total-noArticles") }}</div>
                  </ng-template>
                </span>

                <ng-container *ngIf="cart.decTotalOrderNetAmount" class="summaryPrice">
                  <strong>{{ cart.decTotalOrderNetAmount | translocoCurrency }}</strong>
                </ng-container>
              </div>

              <a
                *ngIf="items.length > 0"
                [routerLink]="'/checkout/cart/' + cart.lngOrderID.toString() | localizeUrlPath"
                class="btn btn--wide text-centered margin--top-l default-font-sizes"
                ><i class="icon-cart"></i> {{ t("core.header-nav.cart-menu.to-cart") }}</a
              >
            </div>
            <!--      <div class="mini-cart-item mini-cart-item&#45;&#45;footer">-->
            <!--        <a href=""><i class="icon-heart"></i> als Merkliste speichern</a>-->
            <!--      </div>-->
          </div>
        </div>
      </div>

      <div class="header-nav-item-text">
        <ng-template #notLoggedin>
          <div>{{ t("core.header-nav.cart-menu.cart") }}</div>
        </ng-template>

        <div *ngIf="isLoggedIn$ | async; else notLoggedin">
          <i class="icon-arrow header-nav-item-text-icon header-nav-item-text-icon--first"></i>
          <div
            class="listname-header-nav"
            *ngIf="context.activeCart"
            [matTooltip]="context.activeCart.sCartName"
            [matTooltipClass]="'tooltip-main'"
            [matTooltipPosition]="'below'"
          >
            {{ context.activeCart.sCartName }}
          </div>
          <i class="icon-arrow header-nav-item-text-icon header-nav-item-text-icon--last"></i>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
