import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AlternateVersionInterface } from '../model/alternate-version.interface';
import { AlternateVersionState, AlternateVersionStore } from '../store/alternate-version.store';

@Injectable({ providedIn: 'root' })
export class AlternateVersionQuery<T extends AlternateVersionInterface> extends Query<AlternateVersionState<T>> {
  constructor(store: AlternateVersionStore<T>) {
    super(store);
  }
}
