<ng-container *transloco="let t; scope: 'scanner'">
  <span
    class="header-nav-item inline-middle default-font-sizes default-font-sizes--small"
    *ngIf="isSupported$ | async"
    (click)="openScanner()"
  >
    <div class="header-dropdown">
      <div class="header-dropdown-label">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="26" height="26" viewBox="0 0 26 26" xml:space="preserve">
          <g transform="matrix(1 0 0 1 13 13)" id="D_wE0x4eD8EqKUnPoqXqA">
            <path
              style="
                stroke: none;
                stroke-width: 1;
                stroke-dasharray: none;
                stroke-linecap: butt;
                stroke-dashoffset: 0;
                stroke-linejoin: miter;
                stroke-miterlimit: 4;
                fill: rgb(255, 255, 255);
                fill-rule: nonzero;
                opacity: 1;
              "
              vector-effect="non-scaling-stroke"
              transform=" translate(-13, -10.5)"
              d="M 0.50375 0 L 4.38217 0 L 4.38217 1.03005 L 1.0073700000000003 1.03005 L 1.0073700000000003 3.72215 L 0.00026000000000037105 3.72215 L 0.00026000000000037105 0.5150299999999999 C 0.00026000000000037105 0.2315299999999999 0.22672000000000037 0.000009999999999843467 0.5038800000000003 0.000009999999999843467 z M 3.79184 3.51225 L 5.53956 3.51225 L 5.53956 17.48775 L 3.7918399999999997 17.48775 z M 14.68649 3.51225 L 15.93488 3.51225 L 15.93488 17.48775 L 14.68649 17.48775 z M 12.26797 3.51225 L 13.20319 3.51225 L 13.20319 17.48775 L 12.26797 17.48775 z M 7.02273 3.51225 L 7.55378 3.51225 L 7.55378 17.48775 L 7.022729999999999 17.48775 z M 20.64907 3.51225 L 22.208419999999997 3.51225 L 22.208419999999997 17.48775 L 20.64907 17.48775 z M 17.41805 3.51225 L 19.165770000000002 3.51225 L 19.165770000000002 17.48775 L 17.41805 17.48775 z M 9.03708 3.51225 L 10.784799999999999 3.51225 L 10.784799999999999 17.48775 L 9.03708 17.48775 z M 1.00724 17.27786 L 1.00724 19.96995 L 4.38204 19.96995 L 4.38204 21 L 0.5036199999999997 21 C 0.22645999999999972 21 -2.220446049250313e-16 20.77058 -2.220446049250313e-16 20.48497 L -2.220446049250313e-16 17.27785 L 1.0071099999999997 17.27785 z M 21.87835 0 L 25.49651 0 C 25.77367 0 26.000130000000002 0.23152 26.000130000000002 0.51502 L 26.000130000000002 3.7221400000000004 L 24.99302 3.7221400000000004 L 24.99302 1.0300500000000006 L 21.878480000000003 1.0300500000000006 L 21.878480000000003 6.661338147750939e-16 z M 26.00013 17.27786 L 26.00013 20.48498 C 26.00013 20.76848 25.77367 21.00001 25.496509999999997 21.00001 L 21.878349999999998 21.00001 L 21.878349999999998 19.96996 L 24.992889999999996 19.96996 L 24.992889999999996 17.27787 L 25.999999999999996 17.27787 z"
              stroke-linecap="round"
            />
          </g>
        </svg>
      </div>

      <div class="header-nav-item-text">
        <div>{{ t("scanner.ui-scan-button.nav-title") }}</div>
      </div>
    </div>
  </span>
</ng-container>
