import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationInterface } from '../../../interfaces/navigation.interface';
import { MenuStore } from '../../../services/menu/menu.store';
import { BreakpointService } from '../../../services/ui/breakpoint.service';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
  @Input() menu!: NavigationInterface[];
  @Input() rootLevel = 0;

  @Input() classPrefix = 'navigation';

  public isTablet = true;

  constructor(private breakpointService: BreakpointService, private menuStore: MenuStore, private location: Location) {}

  public ngOnInit(): void {
    this.breakpointService.tablet.pipe(untilDestroyed(this)).subscribe((isTablet: boolean) => {
      this.isTablet = isTablet;

      if (!this.isTablet) {
        this.menuStore.update({ open: false });
      } else {
        this.restoreState(this.menu);
      }
    });

    this.restoreState(this.menu);
  }

  private restoreState(menu: NavigationInterface[], level = this.rootLevel): boolean {
    for (const item of menu) {
      item.isOpen = false;
      if (!this.isActive(item)) {
        if (item.subNavigation?.length) {
          if (!this.restoreState(item.subNavigation, level + 1)) {
            continue;
          }

          item.isOpen = true;
          return true;
        }

        continue;
      }

      // don't open root menu when direct accent is active
      if (item.subNavigation?.length && level !== 0) {
        item.isOpen = true;
      }

      this.menuStore.update({ openMenuItem: item });
      return true;
    }

    return false;
  }

  isActive(item: NavigationInterface): boolean {
    return this.location.path() === item.url;
  }
}
