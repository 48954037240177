<ng-container *transloco="let t; scope: 'shared'">
  <pagination-template *ngIf="config" class="pagination" #p="paginationApi" [id]="config.id!" (pageChange)="pageChangeHandler($event)">
    <div class="pagination-pages">
      <button
        class="pagination-side-change pagination-side-change--prev default-font-sizes default-font-sizes--small"
        [class.disabled]="p.isFirstPage()"
        [disabled]="p.isFirstPage()"
        (click)="p.previous()"
      >
        <i class="icon-arrow-5"></i>

        <span>{{ t("shared.ui-pagination.pagination.prev-page") }}</span>
      </button>

      <button
        class="pagination-item default-font-sizes default-font-sizes--small"
        *ngFor="let page of p.pages"
        [class.active]="p.getCurrent() === page.value"
        [disabled]="p.getCurrent() === page.value"
        (click)="p.setCurrent(page.value)"
      >
        {{ page.label }}
      </button>

      <button
        class="pagination-side-change pagination-side-change--next default-font-sizes default-font-sizes--small"
        [class.disabled]="p.isLastPage()"
        *ngIf="!p.isLastPage()"
        (click)="p.next()"
      >
        <span>{{ t("shared.ui-pagination.pagination.next-page") }}</span>

        <i class="icon-arrow-5"></i>
      </button>
    </div>

    <!--    <button class="pagination-infinity btn btn&#45;&#45;wide" *ngIf="!p.isLastPage()" (click)="p.next()">-->
    <!--      {{ t("shared.ui-pagination.pagination.load-more") }}-->
    <!--    </button>-->

    <button class="back-to-top" (click)="backToTop()">
      <i class="icon-arrow-6 back-to-top-icon"></i>

      <span>
        {{ t("shared.ui-pagination.pagination.to-top") }}
      </span>

      <i class="icon-arrow-3 back-to-top-icon-mobile"></i>
    </button>
  </pagination-template>
</ng-container>
