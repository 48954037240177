import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ArticleQuery,
  ArticleService,
  BreakpointService,
  CartHeader,
  CartService,
  GoogleTagManagerHelper,
  Product,
  SelectItemEvent,
  SteelCartItem,
  SteelCreateCartItemInterface,
  SteelUnit,
  TrackMethod,
  ViewModeType,
} from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, take } from 'rxjs/operators';
import { WeylandSteelArticle } from '../../interfaces/steel-article.model';
import { iif, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss'],
})
export class ArticleItemComponent implements OnInit {
  @Input() article?: WeylandSteelArticle;
  @Input() articleId?: string;
  @Input() viewMode: ViewModeType = 'grid';
  @Input() product: Product | undefined;
  articleItem?: WeylandSteelArticle;
  unitSelected: SteelUnit | undefined;

  @Output() articleSelected: EventEmitter<WeylandSteelArticle> = new EventEmitter<WeylandSteelArticle>();
  @Output() alternativeArticleSelected: EventEmitter<WeylandSteelArticle> = new EventEmitter<WeylandSteelArticle>();

  public quantity = 1;
  protected loading = false;

  constructor(
    private translocoService: TranslocoService,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
    private articleQuery: ArticleQuery<WeylandSteelArticle>,
    private articleService: ArticleService<WeylandSteelArticle>,
    private breakpointService: BreakpointService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    iif(
      () => !!this.article,
      // true
      of(this.article),
      // false
      of(this.articleId).pipe(
        switchMap((id?: string) =>
          iif(
            () => this.articleQuery.hasEntity(id),
            this.articleQuery.selectEntity(id),
            this.articleService.getArticleByID(id!).pipe(switchMap(() => this.articleQuery.selectEntity(id))),
          ),
        ),
      ),
    )
      .pipe(untilDestroyed(this))
      .subscribe((article: WeylandSteelArticle | undefined) => {
        this.articleItem = article!;
        this.unitSelected = article!.oUnitSelected;
      });
  }

  onAddArticleToCartClick(article: WeylandSteelArticle) {
    if (this.quantity > 0 && !this.loading) {
      this.loading = true;
      this.cartService
        .createCartItem({
          decQuantity: this.quantity,
          sArticleID: article.sArticleID as string,
          sArticleName: article.sName,
          sQuantityUnit: article.sQuantityUnitSales,
          oArticle: article,
          decBO3Factor1: this.unitSelected!.decBO3Factor1,
          decBO3Factor2: this.unitSelected!.decBO3Factor2,
          decBO3Factor3: this.unitSelected!.decBO3Factor3,
          sBO3QuantityUnitInput: this.unitSelected!.sQuantityUnitInput,
          sItemText: '',
          oSteelConfiguration: undefined,
        })
        .pipe(take(1))
        .subscribe(() => {
          this.loading = false;
          this.ref.markForCheck();
        });
    }
  }

  onQuantityChange(quantity: number) {
    this.quantity = quantity;
  }

  selectAlternativeArticle(article: WeylandSteelArticle) {
    this.alternativeArticleSelected.emit(article);
  }

  unitSelectionChanged(oArticleP: WeylandSteelArticle) {
    this.unitSelected = oArticleP.oUnitSelected;
  }

  @TrackMethod<SelectItemEvent>({
    provide: (article: any) => {
      return {
        name: 'select_item',
        payload: {
          ecommerce: {
            item_list_id: article.oProductInfo[0].lngParentGroup.toString(),
            item_list_name: article.oProductInfo[0].sTitle,
            items: [GoogleTagManagerHelper.articleToItem(article)],
          },
        },
      };
    },
  })
  select(article: WeylandSteelArticle) {
    this.articleSelected.emit(article);
  }
}
