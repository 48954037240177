import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ConsentService } from '@lobos/library';

@Component({
  selector: 'app-cookie-consent',
  styleUrls: ['./cookie-consent.component.scss'],
  template: '',
})
export class CookieConsentComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: any, private consentService: ConsentService) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const cookieService = this.consentService;
      const logo =
        '<img src="/assets/images/logo_W101.png" alt="Logo" loading="lazy" style="margin-left: -4px; margin-bottom: -5px; height: 35px">';
      const cookie = '🍪';

      const cc = (window as any).initCookieConsent();
      cc.run({
        onAccept: function () {
          if (cc.allowedCategory('analytics')) {
            cookieService.updateConsent(true);
          }
        },
        categories: ['necessary', 'analytics'],
        level: ['necessary', 'analytics'],
        revision: 0,
        data: null,
        rfc_cookie: false,
        consent_date: '2022-10-18T12:24:52.251Z',
        consent_uuid: 'fc39cb7f-cd46-405d-915a-00ea9a5cffe6',
        last_consent_update: '2022-10-18T12:24:54.140Z',
        gui_options: {
          consent_modal: {
            layout: 'bar', // box,cloud,bar
            position: 'bottom', // bottom,middle,top + left,right,center
            transition: 'slide', // zoom,slide
          },
          settings_modal: {
            layout: 'box', // box,bar
            // position: 'left',                // right,left (available only if bar layout selected)
            transition: 'slide', // zoom,slide
          },
        },
        languages: {
          en: {
            consent_modal: {
              title: cookie + ' Wir verwenden Cookies! ',
              description:
                'Guten Tag, diese Website verwendet wichtige Cookies, um den ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt. <button type="button" data-cc="c-settings" class="cc-link">Lassen Sie mich wählen</button>',
              primary_btn: {
                text: 'Alle akzeptieren',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Alle ablehnen',
                role: 'accept_necessary', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: logo,
              save_settings_btn: 'Einstellungen speichern',
              accept_all_btn: 'Alle akzeptieren',
              reject_all_btn: 'Alle ablehnen',
              close_btn_label: 'Schliessen',
              cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
              blocks: [
                {
                  title: 'Verwendung von Cookies 📢',
                  description:
                    'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a target="_blank" href="https://www.weyland.at/datenschutz/" class="cc-link">Datenschutzerklärung</a>.',
                },
                {
                  title: 'Zwingend notwendige Cookies',
                  description:
                    'Diese Cookies sind für das ordnungsgemäße Funktionieren unserer Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                  },
                },
                {
                  title: 'Leistungs- und Analyse-Cookies',
                  description:
                    'Diese Cookies ermöglichen es der Website, sich an die von Ihnen in der Vergangenheit getroffenen Auswahlen zu erinnern',
                  toggle: {
                    value: 'analytics', // there are no default categories => you specify them
                    enabled: false,
                    readonly: false,
                  },
                  cookie_table: [
                    {
                      col1: '^_ga',
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true,
                    },
                  ],
                },
                {
                  title: 'Weitere Informationen',
                  description:
                    'Bei Fragen zu unserer Politik in Bezug auf Cookies und Ihre Wahlmöglichkeiten, wenden Sie sich bitte an <a class="cc-link" href="mailto:shop@weyland.at">shop@weyland.at</a>.',
                },
              ],
            },
          },
        },
      });
    }
  }
}
