import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { lazyload, placeholder, responsive } from '@cloudinary/ng';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() image: CloudinaryImage | undefined;
  @Input() classes: string = '';
  plugins = [lazyload(), responsive({ steps: 200 }), placeholder()];
}
