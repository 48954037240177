import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {
  public form: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  public error = '';

  public loading = false;
  showMyContainer = false;

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private fb: UntypedFormBuilder,
    private translocoService: TranslocoService,
  ) {}

  requestedPassword() {
    this.showMyContainer = !this.showMyContainer;
  }

  public async login(): Promise<void> {
    const username: string = this.form.value.username;
    const password: string = this.form.value.password;

    this.loading = true;

    try {
      await this.authService.login(username, password);
      this.loading = false;
      this.dialogRef.close('success');
    } catch (exception) {
      this.error = this.translocoService.translate('shared.login-dialog.error-message');
      this.loading = false;
      this.form.controls['password'].reset();
    }
  }
}
