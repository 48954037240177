import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { ImageHelperService } from '../../services/catalog/image-helper.service';
import { Article } from '@lobos/library';

@Pipe({
  name: 'articleManufacturerLogo',
})
export class ArticleManufacturerLogoPipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(article: Article): CloudinaryImage {
    return this.imageHelperService.getArticleManufacturerLogo(article)!;
  }
}
