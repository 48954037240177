import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
  });

  successMessage: boolean = false;

  constructor(protected router: Router, protected translocoService: TranslocoService, private authService: AuthService) {}

  submitPasswordForgot() {
    const email = this.forgotPasswordForm.controls['email'].value;
    this.authService.requestPassword(email);
    this.successMessage = true;
  }
}
