<ng-container *transloco="let t; scope: 'core'">
  <div>
    <div class="default-padding js-header-info bg-sales-rep" *ngIf="customer$ | async as customer">
      <div class="header-info-col default-max-width centered">
        <div class="header-info-item inline-middle default-font-sizes default-font-sizes--very-small flex--jc-space-around">
          <span>{{ t("core.header.sales-rep-active") }}</span>
          <span> ({{ customer.sCompany1 }} {{ customer.sCompany2 }})</span>
        </div>
        <div class="header-info-item inline-middle default-font-sizes default-font-sizes--very-small flex--jc-space-around">
          <a (click)="endSalesRepMode($event)" class="inline-middle default-font-sizes default-font-sizes--very-small">
            <i class="icon-logout inline-middle"></i> <span class="bold">{{ t("core.header.sales-rep-end") }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="header-info-container js-header-info-container inline-container default-padding"
    [class.open]="isOpen"
    [class.notAsSalesRep]="customer$ | async"
  >
    <div
      (click)="toggleOpen()"
      class="flex header-info-mobile-opener js-header-info-mobile-opener bright-text default-font-sizes default-font-sizes--very-small flex--jc-space-between"
    >
      <span>{{ t("core.header.title") }}</span>

      <span *ngIf="customer$ | async" (click)="endSalesRepMode($event)"
        >{{ t("core.header.sales-rep-active") }} <i class="icon-logout"></i
      ></span>

      <i class="icon-arrow-2"></i>
    </div>

    <div class="header-info js-header-info default-max-width centered">
      <div class="header-info-col">
        <div class="header-info-item inline-middle">
          <i class="icon-phone inline-middle"></i>

          <a href="tel:{{ t('core.header.phone') }}" class="inline-middle default-font-sizes default-font-sizes--very-small">
            {{ t("core.header.phone") }}
          </a>
        </div>

        <div class="header-info-item inline-middle">
          <i class="icon-mail inline-middle"></i>

          <a href="mailto:{{ t('core.header.mail') }}" class="inline-middle default-font-sizes default-font-sizes--very-small">
            {{ t("core.header.mail") }}
          </a>
        </div>
      </div>

      <div class="header-info-col tablet-hidden">
        <div class="header-info-item inline-middle">
          <i class="icon-delivery inline-middle"></i>

          <span class="inline-middle default-font-sizes default-font-sizes--very-small">
            {{ t("core.header.lead") }}
          </span>
        </div>
      </div>

      <div class="header-info-col">
        <div class="header-info-item inline-middle">
          <i class="icon-map-marker inline-middle"></i>

          <a
            href="{{ t('core.header.locations-link') }}"
            target="_blank"
            class="inline-middle default-font-sizes default-font-sizes--very-small"
          >
            {{ t("core.header.locations") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
