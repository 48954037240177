import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AlternateVersionInterface } from '../model/alternate-version.interface';
import { AlternateVersionStore } from '../store/alternate-version.store';

@Injectable({ providedIn: 'root' })
export class AlternateVersionService<T extends AlternateVersionInterface> {
  static defaultVersions: AlternateVersionInterface[] = environment.availableLang.map((lang: string) => ({
    id: lang,
    key: lang,
    title: lang.toUpperCase(),
    url: `/${lang}`,
  }));

  constructor(private store: AlternateVersionStore<T>, private location: Location) {}

  update(versions: T[]): any {
    return this.store.update({ versions });
  }

  /**
   * Replaces the language parameter in the current url
   */
  getSimple(): T[] {
    return environment.availableLang.map(
      (lang: string) =>
        ({
          id: lang,
          key: lang,
          title: lang.toUpperCase(),
          url: this.location
            .path()
            .replace(/^\/[a-z]+\//, `/${lang}/`)
            .replace(/^\/[a-z]+[^\/]$/, `/${lang}`),
        } as T),
    );
  }
}
