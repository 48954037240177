import { Component, Input } from '@angular/core';
import { AuthService, Price, PriceQuery, PriceService, SteelArticle, User } from '@lobos/library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-article-price',
  templateUrl: './article-price.component.html',
  styleUrls: ['./article-price.component.scss'],
})
export class ArticlePriceComponent {
  @Input() bGrossPrice = false;
  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  user$: Observable<User | undefined> = this.authService.authUser$;
  private articleChanged$: BehaviorSubject<SteelArticle | undefined> = new BehaviorSubject<SteelArticle | undefined>(undefined);
  oPrice$: Observable<Price | undefined> = this.articleChanged$.pipe(
    switchMap(() =>
      iif(
        () => this.priceQuery.hasEntity(this.article?.sArticleID),
        this.priceQuery.selectEntity(this.article?.sArticleID),
        this.getPriceFromApi(this.article!).pipe(switchMap(() => this.priceQuery.selectEntity(this.article?.sArticleID))),
      ),
    ),
  );

  constructor(private priceService: PriceService, private priceQuery: PriceQuery, private authService: AuthService) {}

  private _article: SteelArticle | undefined;

  get article(): SteelArticle | undefined {
    return this._article;
  }

  @Input() set article(value: SteelArticle | undefined) {
    if (value) {
      this._article = value;
      this.articleChanged$.next(value);
    }
  }

  getPriceFromApi(article: SteelArticle): Observable<Price | undefined> {
    return this.priceService.get(article.sArticleID, article.sQuantityUnitSales, environment.defaultCurrency, article.lngSalesPriceUnit);
  }
}
