import { Component, ElementRef, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, iif, Observable, of } from 'rxjs';
import {
  CartHeader,
  CartHeaderQuery,
  CartItemQuery,
  FavoriteItemQuery,
  FavoriteList,
  FavoriteListQuery,
  ScannerAdapterAbstract,
  SteelCartItem,
  SteelFavoriteItemModel,
} from '@lobos/library';
import { MatDialog } from '@angular/material/dialog';
import { ScanNotificationService } from '../scanner/scan-notification.service';
import { BreakpointService } from '../../services/ui/breakpoint.service';
import { ScannerComponent } from '../scanner/scanner/scanner.component';
import { DOCUMENT } from '@angular/common';
import { ID } from '@datorama/akita';
import { WeylandFavoriteItem } from '../../interfaces/favorite-item.model';

enum MOBILE_MENUS {
  SCANNER = 'scanner',
  FAVORITE = 'favorite',
  CART = 'cart',
  PROFILE = 'profile',
  INITIAL = 'initial',
}

@UntilDestroy()
@Component({
  selector: 'app-mobile-footer-nav',
  templateUrl: './mobile-footer-nav.component.html',
  styleUrls: ['./mobile-footer-nav.component.scss'],
})
export class MobileFooterNavComponent {
  public mobileMenu = MOBILE_MENUS;
  public activeMenu: MOBILE_MENUS = MOBILE_MENUS.INITIAL;

  activeFavoriteItems$: Observable<SteelFavoriteItemModel[]> = this.favoriteListQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.favoriteItemQuery.selectFavoriteItems(id!)),
  );

  public activeCartItems$: Observable<SteelCartItem[]> = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.cartItemQuery.selectCartItems(id!)),
  );

  public isSupported$: Observable<boolean> = combineLatest([this.breakpointService.mobile, this.breakpointService.tablet]).pipe(
    map(([mobile, tablet]: [boolean, boolean]) => mobile || tablet),
    switchMap((correctDevice: boolean) => iif(() => correctDevice, this.scannerAdapter.isSupported(), of(correctDevice))),
    catchError((error: Error) => of(!this.scannerAdapter.handleError(error, false, true))),
  );

  @HostListener('document:click', ['$event'])
  clickout(event: { target: HTMLElement }) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.setMenuInitialState();
    }
  }

  constructor(
    private router: Router,
    private eRef: ElementRef,
    private scannerAdapter: ScannerAdapterAbstract,
    private dialog: MatDialog,
    private scanNotification: ScanNotificationService,
    private breakpointService: BreakpointService,
    private favoriteListQuery: FavoriteListQuery<FavoriteList>,
    private favoriteItemQuery: FavoriteItemQuery<WeylandFavoriteItem>,
    private cartHeaderQuery: CartHeaderQuery<CartHeader>,
    private cartItemQuery: CartItemQuery<SteelCartItem>,
    @Inject(DOCUMENT) private document: Document,
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => this.setMenuInitialState()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  activateMenuContent(menu: MOBILE_MENUS): void {
    if (this.activeMenu !== MOBILE_MENUS.INITIAL && this.activeMenu === menu) {
      this.setMenuInitialState();
    } else {
      this.activeMenu = menu;
      this.document.body.classList.add('no-scroll');
    }
  }

  setMenuInitialState(): void {
    this.activeMenu = MOBILE_MENUS.INITIAL;
    this.document.body.classList.remove('no-scroll');
  }

  public openScanner(): void {
    this.scanNotification.prepare();
    this.scannerAdapter
      .askForPermission()
      .pipe(
        catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((result: boolean) => result),
        untilDestroyed(this),
      )
      .subscribe(() => this.dialog.open(ScannerComponent, { hasBackdrop: true, panelClass: 'scanner-panel' }));
  }
}
