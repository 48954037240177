<div *transloco="let t">
  <div class="flex flex--jc-space-between">
    <h1 class="mat-h1">{{ t("shared.article-availability.availability") }}</h1>
    <button mat-icon-button mat-dialog-close>
      <i class="icon-close"></i>
    </button>
  </div>

  <div mat-dialog-content>
    <mat-grid-list cols="{{ shtStockLevel > 2 ? 12 : 8 }}" rowHeight="32px" gutterSize="16px">
      <ng-container *ngFor="let oStockInfo of (stockAvailability$ | async)?.oStockInfoColl">
        <ng-container *ngIf="oStockInfo.decAvailable > 1">
          <mat-grid-tile colspan="2" class="text-left">
            <div
              class="delivery-status {{ evaluateStockAvailability(oStockInfo, shtStockLevel, true) }}"
              title="{{ evaluateStockAvailability(oStockInfo, shtStockLevel, true) }}"
            >
              <div class="delivery-status-dot"></div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="4" class="text-right" *ngIf="shtStockLevel > 2 && (user$ | async) as user">
            <ng-container *ngIf="user.shtRoleID !== 1 && user.shtRoleID !== 2">
              {{ oStockInfo.decAvailable }}&nbsp;{{ oStockInfo.sQuantityUnit }}
            </ng-container>
          </mat-grid-tile>
          <mat-grid-tile colspan="6" class="text-left">
            {{ oStockInfo.sStorageLocationCity }}
          </mat-grid-tile>
        </ng-container>
      </ng-container>
    </mat-grid-list>
  </div>
</div>
