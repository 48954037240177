<header class="header js-header" [class.open]="isOpen$ | async" [class.sticky]="isSticky">
  <div class="header-top">
    <app-header-info></app-header-info>

    <app-header-main (toggelMenu)="toggleMenu()"></app-header-main>
  </div>

  <nav class="navigation default-padding" shop-ui-header-navigation></nav>

  <app-breadcrumb></app-breadcrumb>
</header>
