import { Component, Input } from '@angular/core';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { Article, Price, PriceQuery } from '@lobos/library';
import { switchMap } from 'rxjs/operators';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';

@Component({
  selector: 'app-bulk-price',
  templateUrl: './bulk-price.component.html',
  styleUrls: ['./bulk-price.component.scss'],
})
export class BulkPriceComponent {
  private _article: Article | undefined;
  @Input() set article(value: Article | undefined) {
    this._article = value;
    this.articleChanged$.next(value);
  }

  get article(): Article | undefined {
    return this._article;
  }

  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);

  constructor(private articleHelper: ArticleHelperService, private priceQuery: PriceQuery) {}

  oPrice$: Observable<Price | undefined> = this.articleChanged$.pipe(
    switchMap(() =>
      iif(
        () => this.priceQuery.hasEntity(this.article?.sArticleID),
        this.priceQuery.selectEntity(this.article?.sArticleID),
        this.articleHelper.getPriceFromApi(this.article!).pipe(switchMap(() => this.priceQuery.selectEntity(this.article?.sArticleID))),
      ),
    ),
  );
}
