<div class="footer-notes default-padding">
  <div class="default-max-width centered">
    <div class="flex flex--jc-space-between default-font-sizes default-font-sizes--small">
      <div>
        {{ footer.copyright_footer_notes }}
      </div>

      <div class="inline-container footer-notes-nav">
        <a
          *ngFor="let footerLink of footer.footer_notes"
          [href]="footerLink.url"
          class="default-font-sizes default-font-sizes--small"
          [target]="footerLink.linkNewTab ? '_blank' : '_self'"
        >
          {{ footerLink?.link }}
        </a>
      </div>
    </div>
  </div>
</div>
