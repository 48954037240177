import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AuthService,
  CartHeader,
  CartHeaderQuery,
  CartItemQuery,
  CartService,
  SteelCartItem,
  SteelCreateCartItemInterface,
} from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { filter, switchMap, take } from 'rxjs/operators';
import { ID } from '@datorama/akita';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {
  @Output() closeMobileNav: EventEmitter<void> = new EventEmitter<void>();

  public carts$: Observable<CartHeader[]> = this.cartHeaderQuery.selectAll();
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public loading = false;

  public activeCart$: Observable<CartHeader | undefined> = this.cartHeaderQuery.selectActive();
  public activeCartItems$: Observable<SteelCartItem[]> = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.cartItemQuery.selectCartItems(id!)),
  );

  constructor(
    private cartHeaderQuery: CartHeaderQuery<CartHeader>,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
    private cartItemQuery: CartItemQuery<SteelCartItem>,
    private translocoService: TranslocoService,
    private authService: AuthService,
  ) {}

  setActiveCart(cart: CartHeader) {
    this.cartService.changeActiveCart(cart.lngOrderID).pipe(take(1)).subscribe();
  }

  onQuantityChange(quantity: number, item: SteelCartItem) {
    const tempItem: SteelCartItem = { ...item, decBO3QuantityInput: quantity };

    this.cartService.updateCartItem(tempItem).pipe(take(1)).subscribe();
  }

  identifyCartItem(_: any, cartItem: SteelCartItem) {
    return cartItem.shtFixedItemID;
  }

  openDeleteArticleFromCart(cartItem: SteelCartItem) {
    this.loading = true;
    this.cartService
      .deleteCartItem(cartItem)
      .pipe(take(1))
      .subscribe(() => (this.loading = false));
  }
}
