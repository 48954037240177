import { Pipe, PipeTransform } from '@angular/core';
import { ArticleCount, Product } from '@lobos/library';

@Pipe({
  name: 'articleCount',
})
export class ArticleCountPipe implements PipeTransform {
  public transform(product: Product, articleCount: ArticleCount[] = []): number {
    return articleCount.filter((count: ArticleCount) => product.lngGroup === count.lngGroupId)[0]?.count || 0;
  }
}
