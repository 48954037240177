import { Component, Input } from '@angular/core';
import { WeylandSteelArticle } from '../../../interfaces/steel-article.model';

@Component({
  selector: 'app-scan-result',
  templateUrl: './scan-result.component.html',
  styleUrls: ['./scan-result.component.scss'],
})
export class ScanResultComponent {
  @Input()
  public articles: WeylandSteelArticle[] | undefined;
}
