import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AuthService,
  CartHeader,
  CartService,
  FavoriteItemQuery,
  FavoriteList,
  FavoriteListQuery,
  FavoriteService,
  SteelCartItem,
  SteelCreateCartItemInterface,
  SteelFavoriteItemModel,
} from '@lobos/library';
import { filter, switchMap, take } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { WeylandFavoriteItem } from '../../../interfaces/favorite-item.model';
import { TranslocoService } from '@ngneat/transloco';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
})
export class FavoriteComponent {
  @Output() closeMobileNav: EventEmitter<void> = new EventEmitter<void>();

  activeFavoriteList$: Observable<FavoriteList | undefined> = this.favoriteListQuery.selectActive();
  activeFavoriteItems$: Observable<SteelFavoriteItemModel[]> = this.favoriteListQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.favoriteItemQuery.selectFavoriteItems(id!)),
  );
  favoriteLists$: Observable<FavoriteList[]> = this.favoriteListQuery.selectAll();

  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  constructor(
    private favoriteListQuery: FavoriteListQuery<FavoriteList>,
    private favoriteItemQuery: FavoriteItemQuery<WeylandFavoriteItem>,
    private favoriteService: FavoriteService<FavoriteList, SteelFavoriteItemModel>,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
    private translocoService: TranslocoService,
    private authService: AuthService,
  ) {}

  setActiveFavoriteList(favoriteList: FavoriteList) {
    this.favoriteService.getFavoriteItems(favoriteList.gListID).pipe(take(1), untilDestroyed(this)).subscribe();
    this.favoriteService.setActiveFavoriteId(favoriteList.gListID);
  }

  async onQuantityChange(favoriteItem: SteelFavoriteItemModel, quantity: number) {
    await this.favoriteService.updateFavoriteItem({ ...favoriteItem, decQuantity: quantity }).toPromise();
  }

  async onAddArticleToCartClick(favoriteItem: SteelFavoriteItemModel) {
    if (favoriteItem.decQuantity > 0) {
      const selectedUnit = favoriteItem.oArticle.oUnitColl.find((unit) => unit.sQuantityUnitInput === favoriteItem.sB03QuantityUnitInput);
      await this.cartService
        .createCartItem({
          decQuantity: favoriteItem.decQuantity,
          sArticleID: favoriteItem.oArticle.sArticleID as string,
          sArticleName: favoriteItem.oArticle.sName,
          sQuantityUnit: favoriteItem.oArticle.sQuantityUnitSales,
          oArticle: favoriteItem.oArticle,
          decBO3Factor1: selectedUnit!.decBO3Factor1,
          decBO3Factor2: selectedUnit!.decBO3Factor2,
          decBO3Factor3: selectedUnit!.decBO3Factor3,
          sBO3QuantityUnitInput: favoriteItem.sB03QuantityUnitInput,
          sItemText: '',
          oSteelConfiguration: undefined,
        })
        .toPromise();
    }
  }

  async deleteFavoriteItem(favoriteItem: SteelFavoriteItemModel) {
    await this.favoriteService.deleteFavoriteItem(favoriteItem).toPromise();
  }
}
