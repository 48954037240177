export const environmentCommon = {
  production: false,
  debugging: true,
  host: 'http://localhost:4300',
  apiUrl: '/p48apihost',
  enhancedEcommerce: false,
  defaultCurrency: 'EUR',
  defaultLocale: 'de-AT',
  availableLang: ['de'],
  defaultLanguage: 'de',
  shippingCostArticleIDs: ['FRZKA002'],
  version: VERSION,
  ssrPort: 4000,
  loadTranslationFromServer: false,

  api: {
    /** used for development, where root host and api host are different  */
    host: 'https://api.lobos.ch/weyland',
    branchKey: 'W101',
    webshopId: 1,
  },

  cms: {
    host: 'http://localhost:1337',
    apiUrl: '/cms/v4',
  },

  auth: {
    routes: {
      home: '/',
      login: '/auth/login',
    },
  },

  images: {
    categoryImage: 101,
    productDetailImages: [101, 102],
    manufacturerLogo: 103,
    labelImages: 104,

    cloudinary: {
      active: true,
      cloudName: 'weyland-gmbh',
      fetchImageUrl: 'https://shop.weyland.at/images',
    },
  },

  oauth2: {
    adminBasePath: 'http://localhost:4445',
  },

  sentry: {
    dsn: 'https://18d1b5ea3f4f4bbeb549f18a44ef7aa3@o920539.ingest.sentry.io/6621923',
    active: false,
  },

  tracking: {
    gtm: 'GTM-KR8BH22',
  },
};

import { VERSION } from './version';
