<ng-container *transloco="let t; scope: 'catalog'">
  <ng-container *ngIf="articleItem">
    <ng-container *ngIf="viewMode === 'grid'">
      <a
        (click)="select(articleItem)"
        [routerLink]="['article' | localizeUrlPath, articleItem.sArticleID]"
        [queryParams]="{ _productId: product?.Id }"
        class="card-product-top"
      >
        <div class="card-product-top-image">
          <app-image [image]="articleItem | articleMainImage"></app-image>
        </div>
      </a>

      <div class="card-product-top-buttons">
        <app-is-favorite
          *appIsBrowser
          [emptySymbol]="'icon-heart'"
          [fullSymbol]="'icon-heart-filled'"
          [article]="articleItem"
        ></app-is-favorite>
        <!--      <button><i class='icon-compare-2'></i></button>-->
      </div>

      <div class="relative card-product-bottom">
        <div class="flex card-product-description flex--d-column flex--jc-space-between">
          <div>
            <a
              (click)="select(articleItem)"
              [routerLink]="['article' | localizeUrlPath, articleItem.sArticleID]"
              [queryParams]="{ _productId: product?.Id }"
              class="card-product-description-top"
            >
              <div>
                <div class="default-font-sizes bold articleName">
                  {{ articleItem.sName }}
                </div>

                <div class="default-font-sizes" [innerHtml]="articleItem.oProductInfo[0].sDescriptionShort"></div>

                <div
                  class="default-font-sizes default-font-sizes--ultra-small card-product-description-art-nr margin--bottom-m margin--top-xxs"
                >
                  {{
                    t("catalog.article-list.item-number", {
                      articleId: article?.sArticleID,
                      uniformNumber: article?.sUniformNumber
                    })
                  }}
                </div>
              </div>
            </a>
            <a
              (click)="select(articleItem)"
              [routerLink]="['article' | localizeUrlPath, articleItem.sArticleID]"
              [queryParams]="{ _productId: product?.Id }"
              class="relative default-font-sizes card-product-description-bottom-price"
            >
              <app-article-price *appIsBrowser [article]="articleItem" [bGrossPrice]="true"></app-article-price>
            </a>
            <app-article-availability
              *appIsBrowser
              class="article_availability"
              [sArticleID]="articleItem.sArticleID"
              [decQty]="articleItem.decQuantityPackage ? articleItem.decQuantityPackage : 1"
            >
            </app-article-availability>
          </div>

          <div class="card-product-description-bottom">
            <div class="card-product-description-bottom-cart">
              <div class="card-product-top-buttons card-product-top-buttons--list">
                <button>
                  <app-is-favorite
                    *appIsBrowser
                    [emptySymbol]="'icon-heart icon--big'"
                    [fullSymbol]="'icon-heart-filled icon--big'"
                    [article]="articleItem"
                  >
                  </app-is-favorite>
                </button>
              </div>

              <div class="flex">
                <div class="default-font-sizes--small unit-selector">
                  <app-article-unit
                    [article]="articleItem"
                    [bLabel]="false"
                    (articleChange)="unitSelectionChanged($event)"
                  ></app-article-unit>
                </div>

                <app-quantity-input (onQuantityChange)="onQuantityChange($event)"></app-quantity-input>
                <a
                  (click)="onAddArticleToCartClick(articleItem)"
                  class="card-product-description-bottom-cart-button card-product-description-bottom-cart-button--no-config"
                >
                  <i class="icon-cart"></i>
                </a>

                <div *ngIf="unitSelected && unitSelected.shtP48Configurator === 1; else notConfigurable">
                  <a
                    class="card-product-description-bottom-cart-button"
                    [routerLink]="['configuration' | localizeUrlPath, articleItem.sArticleID]"
                    [queryParams]="{ unit: unitSelected | selectedUnitQueryParam }"
                  >
                    <i class="icon-setting"></i>
                  </a>
                </div>

                <ng-template #notConfigurable>
                  <button
                    [disabled]="loading"
                    class="card-product-description-bottom-cart-button"
                    (click)="onAddArticleToCartClick(articleItem)"
                  >
                    <i *ngIf="!loading" class="icon-cart"></i>
                    <mat-spinner *ngIf="loading" class="addToCartSpinner" [diameter]="30"></mat-spinner>
                  </button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="viewMode === 'list'" class="list-container">
      <div class="article-container">
        <div class="side-section">
          <div class="img-container">
            <app-image [image]="articleItem | articleMainImage"></app-image>
          </div>
          <div>
            <app-article-availability
              *appIsBrowser
              [sArticleID]="articleItem.sArticleID"
              [decQty]="articleItem.decQuantityPackage ? articleItem.decQuantityPackage : 1"
            >
            </app-article-availability>
          </div>
        </div>
        <div class="content-section">
          <a
            class="link-to-article"
            (click)="select(articleItem)"
            [routerLink]="['article' | localizeUrlPath, articleItem.sArticleID]"
            [queryParams]="{ _productId: product?.Id }"
          >
            <div class="headline-fourth bold margin--bottom-s">
              {{ articleItem.sName }}
            </div>
          </a>
          <div class="default-text margin--top-s">{{ t("catalog.article-item.item-number") }} {{ articleItem.sArticleID }}</div>
          <div class="feature-container margin--top-l">
            <div class="product-feature default-font-sizes--very-small" *ngFor="let feature of article | articleFeatureList : product">
              <div class="bold">{{ feature.sName }}</div>
              {{ feature.sValue }} <br /><br />
            </div>
          </div>
          <!--        <div class='percentage'>-->
          <!--          &lt;!&ndash; TODO dynamic value&ndash;&gt;-->
          <!--          30%-->
          <!--        </div>-->
          <div class="price headline-fourth bold">
            <app-article-price *appIsBrowser [article]="articleItem" [bGrossPrice]="false"></app-article-price>
          </div>
          <div class="right-side">
            <div class="selector unit">
              <app-article-unit
                [(article)]="articleItem"
                [bLabel]="false"
                (articleChange)="unitSelectionChanged($event)"
              ></app-article-unit>
            </div>
            <div class="selector quantity">
              <app-quantity-input (onQuantityChange)="onQuantityChange($event)"></app-quantity-input>
            </div>

            <br />

            <div class="cart-button">
              <div *ngIf="unitSelected && unitSelected.shtP48Configurator === 1; else notConfigurable">
                <a
                  class="btn btn--wide configuration"
                  [routerLink]="['configuration' | localizeUrlPath, articleItem.sArticleID]"
                  [queryParams]="{ unit: unitSelected | selectedUnitQueryParam }"
                >
                  {{ t("catalog.article-item.configure") }}
                </a>
              </div>

              <ng-template #notConfigurable>
                <button [disabled]="loading" class="btn btn--wide addToCartBtn" (click)="onAddArticleToCartClick(articleItem)">
                  <mat-spinner *ngIf="loading" class="addToCartSpinner" [diameter]="30"></mat-spinner>
                  <ng-container *ngIf="!loading">{{ t("catalog.article-item.button-cart") }}</ng-container>
                </button>
              </ng-template>
            </div>

            <div class="icons">
              <app-is-favorite
                *appIsBrowser
                class="heart-icon-container"
                [emptySymbol]="'icon-heart'"
                [fullSymbol]="'icon-heart-filled'"
                [article]="articleItem"
              ></app-is-favorite>

              <button
                *ngIf="articleItem.oAccessories.length > 0"
                (click)="selectAlternativeArticle(articleItem)"
                matTooltip="Passende Artikel"
                [matTooltipClass]="'tooltip-main'"
                [matTooltipPosition]="'above'"
                class="similar-button"
              >
                <i class="icon-grid-mobile"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
