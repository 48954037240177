import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';
import {
  Article,
  createSteelFavoriteItem,
  FavoriteItemQuery,
  FavoriteList,
  FavoriteListQuery,
  FavoriteService,
  SteelFavoriteItemModel,
} from '@lobos/library';

@UntilDestroy()
@Component({
  selector: 'app-is-favorite',
  templateUrl: './is-favorite.component.html',
  styleUrls: ['./is-favorite.component.scss'],
})
export class IsFavoriteComponent {
  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);
  private _article: Article | undefined;
  @Input() set article(value: Article | undefined) {
    if (value) {
      this._article = value;
      this.articleChanged$.next(value);
    }
  }

  get article(): Article | undefined {
    return this._article;
  }

  @Input() fullSymbol = 'icon-heart-filled';
  @Input() emptySymbol = 'icon-heart';

  isFavorite$: Observable<boolean> = this.articleChanged$.pipe(
    switchMap(() => this.favoriteListQuery.selectActiveId()),
    switchMap((id) => this.favoriteItemQuery.selectFavoriteItems(id!)),
    map((items: SteelFavoriteItemModel[]) => {
      return !!items.find(
        (item) =>
          item.oArticle?.sArticleID === this.article?.sArticleID &&
          item.sB03QuantityUnitInput === this.article?.oUnitSelected?.sQuantityUnitInput,
      );
    }),
  );

  constructor(
    private favoriteItemQuery: FavoriteItemQuery<SteelFavoriteItemModel>,
    private favoriteService: FavoriteService<FavoriteList, SteelFavoriteItemModel>,
    private favoriteListQuery: FavoriteListQuery<FavoriteList>,
  ) {}

  addArticleToFavoriteList(article: Article) {
    const activeFavoriteListId = this.favoriteListQuery.getActiveId();
    const newFavoriteItem = createSteelFavoriteItem({
      gListID: activeFavoriteListId as string, // TODO refactor in library
      oArticle: article as any,
      decQuantity: 1,
      sB03QuantityUnitInput: article.oUnitSelected.sQuantityUnitInput,
    });
    this.favoriteService.createFavoriteItem(newFavoriteItem, activeFavoriteListId!).pipe(take(1), untilDestroyed(this)).subscribe();
  }

  removeArticleFromFavoriteList(article: Article) {
    this.favoriteItemQuery
      .selectEntity(
        (item: SteelFavoriteItemModel) =>
          item.oArticle.sArticleID === article.sArticleID &&
          item.sB03QuantityUnitInput === article.oUnitSelected.sQuantityUnitInput &&
          item.gListID === this.favoriteListQuery.getActiveId(),
      )
      .pipe(
        first(),
        switchMap((favoriteItem: SteelFavoriteItemModel | undefined) => this.favoriteService.deleteFavoriteItem(favoriteItem!)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
