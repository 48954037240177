import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocalizeUrlPathModule } from '@lobos/library';
import { TranslocoModule } from '@ngneat/transloco';
import { CartStepBreadcrumbComponent } from './cart-step-breadcrumb/cart-step-breadcrumb.component';
import { CartDetailConfigurationInfoComponent } from './cart-detail-configuration-info/cart-detail-configuration-info.component';
import { CartDetailItemTextOptionalComponent } from './cart-detail-item-text-optional/cart-detail-item-text-optional.component';

const components: any[] = [CartStepBreadcrumbComponent, CartDetailConfigurationInfoComponent, CartDetailItemTextOptionalComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, RouterModule, TranslocoModule, LocalizeUrlPathModule],
  exports: components,
})
export class CheckoutComponentsModule {}
