<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="activeCartItems$ | async as items">
    <div class="mobile-nav-header">
      <div class="bold"><i class="icon-cart"></i> {{ t("core.header-nav.cart-menu.cart") }}</div>
      <i class="icon-close" (click)="closeMobileNav.emit()"></i>
    </div>

    <div class="cart-content">
      <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, activeCart: activeCart$ | async } as context">
        <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
          <div class="cart-menu-item">
            <mat-form-field>
              <select matNativeControl [ngModel]="context.activeCart" (ngModelChange)="setActiveCart($event)">
                <option disabled>{{ t("core.header-nav.cart-menu.choose-cart") }}</option>
                <option *ngFor="let cart of carts$ | async" [ngValue]="cart">
                  {{ t("core.header-nav.cart-menu.cart") }} «{{ cart?.sCartName }}»
                </option>
              </select>
            </mat-form-field>
          </div>

          <div class="cart-menu-item">
            <a [routerLink]="'/profile/carts' | localizeUrlPath" class="mini-cart-item-justify">
              <span>{{ t("core.header-nav.cart-menu.manage-carts") }}</span
              ><i class="icon-setting"></i>
            </a>
          </div>

          <div class="cart-menu-item">
            <a [routerLink]="'/profile/carts/new' | localizeUrlPath" class="mini-cart-item-justify">
              <span>{{ t("core.header-nav.cart-menu.new-cart") }}</span
              ><i class="icon-plus"></i>
            </a>
          </div>

          <div class="cart-article-list">
            <ng-container
              *ngFor="let item of items; trackBy: identifyCartItem"
              [ngClass]="{ 'routing-detail-highlight': !!item.shtProcessedItem || item.oArticle.oProductInfo.length === 0 }"
            >
              <div class="cart-article-item">
                <div class="item-upper-row">
                  <div class="article-image">
                    <app-image [image]="item.oArticle | articleMainImage"></app-image>
                  </div>

                  <div>
                    <div class="article-name">
                      <ng-container *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0; else elseBlock">
                        <a [routerLink]="['/article/' + item.sArticleID | localizeUrlPath]"
                          ><strong>{{ item.sArticleName }}</strong>
                        </a>
                      </ng-container>
                      <ng-template #elseBlock>
                        <strong>{{ item.sArticleName }}</strong>
                      </ng-template>
                    </div>
                    <p class="article-number">
                      {{ t("core.header-nav.cart-menu.article-number") }}: <strong>{{ item.sArticleID }}</strong>
                    </p>
                  </div>

                  <div>
                    <div
                      class="deleteArticle"
                      *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0"
                      (click)="openDeleteArticleFromCart(item)"
                    >
                      <i *ngIf="!loading" class="icon-trash"></i>
                      <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
                    </div>
                  </div>
                </div>

                <div class="item-lower-row">
                  <div class="input-section">
                    <app-quantity-input
                      *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0"
                      [quantity]="item.decBO3QuantityInput ? item.decBO3QuantityInput : item.decQuantityOrdered"
                      (onQuantityChange)="onQuantityChange($event, item)"
                    >
                    </app-quantity-input>
                  </div>

                  <div class="unit-section">
                    <ul class="separated-list" *ngIf="item.sBO3Title1 || item.sBO3Title2 || item.sBO3Title3">
                      <li *ngIf="item.sBO3Title1">{{ item.decBO3Factor1 + " " + item.sBO3Title1 }}</li>
                      <li *ngIf="item.sBO3Title2">{{ item.decBO3Factor2 + " " + item.sBO3Title2 }}</li>
                      <li *ngIf="item.sBO3Title3">{{ item.decBO3Factor3 + " " + item.sBO3Title3 }}</li>
                    </ul>
                  </div>

                  <div class="article-price">
                    <app-article-price [article]="item.oArticle"></app-article-price>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #notLoggedIn>
          <div class="cart-article-list">
            <ng-container
              *ngFor="let item of items; trackBy: identifyCartItem"
              [ngClass]="{ 'routing-detail-highlight': !!item.shtProcessedItem || item.oArticle.oProductInfo.length === 0 }"
            >
              <div class="cart-article-item">
                <div class="item-upper-row">
                  <div class="article-image">
                    <app-image [image]="item.oArticle | articleMainImage"></app-image>
                  </div>

                  <div>
                    <div class="article-name">
                      <ng-container *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0; else elseBlock">
                        <a [routerLink]="['/article/' + item.sArticleID | localizeUrlPath]"
                          ><strong>{{ item.sArticleName }}</strong>
                        </a>
                      </ng-container>
                      <ng-template #elseBlock>
                        <strong>{{ item.sArticleName }}</strong>
                      </ng-template>
                    </div>
                    <p class="article-number">
                      {{ t("core.header-nav.cart-menu.article-number") }}: <strong>{{ item.sArticleID }}</strong>
                    </p>
                  </div>

                  <div>
                    <div
                      class="deleteArticle"
                      *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0"
                      (click)="openDeleteArticleFromCart(item)"
                    >
                      <i *ngIf="!loading" class="icon-trash"></i>
                      <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
                    </div>
                  </div>
                </div>

                <div class="item-lower-row">
                  <div class="input-section">
                    <app-quantity-input
                      *ngIf="!item.shtProcessedItem && item.oArticle.oProductInfo.length > 0"
                      [quantity]="item.decBO3QuantityInput ? item.decBO3QuantityInput : item.decQuantityOrdered"
                      (onQuantityChange)="onQuantityChange($event, item)"
                    >
                    </app-quantity-input>
                  </div>

                  <div class="unit-section">
                    <ul class="separated-list" *ngIf="item.sBO3Title1 || item.sBO3Title2 || item.sBO3Title3">
                      <li *ngIf="item.sBO3Title1">{{ item.decBO3Factor1 + " " + item.sBO3Title1 }}</li>
                      <li *ngIf="item.sBO3Title2">{{ item.decBO3Factor2 + " " + item.sBO3Title2 }}</li>
                      <li *ngIf="item.sBO3Title3">{{ item.decBO3Factor3 + " " + item.sBO3Title3 }}</li>
                    </ul>
                  </div>

                  <div class="article-price">
                    <app-article-price [article]="item.oArticle"></app-article-price>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>

        <div *ngIf="context.activeCart as cart">
          <ng-container *ngIf="items.length > 0; else noArticles">
            <div class="articles-in-cart">
              <span>{{ "core.header-nav.total-plurality" | transloco : { totalArticleVar: items.length } }} </span>
              <ng-container *ngIf="cart.decTotalOrderNetAmount" class="summaryPrice">
                <span>
                  <strong>{{ cart.decTotalOrderNetAmount | translocoCurrency }}</strong></span
                >
              </ng-container>
            </div>
          </ng-container>

          <ng-template #noArticles>
            <div class="articles-in-cart">{{ t("core.header-nav.total-noArticles") }}</div>
          </ng-template>

          <div class="cart-button-bottom" *ngIf="items.length > 0">
            <a
              [routerLink]="'/checkout/cart/' + cart.lngOrderID.toString() | localizeUrlPath"
              class="btn btn--bright btn--wide text-centered default-font-sizes"
              ><i class="icon-cart"></i> {{ t("core.header-nav.cart-menu.to-cart") }}</a
            >
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
