import { Pipe, PipeTransform } from '@angular/core';
import { UrlPathHelperService } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'urlPath',
})
export class UrlPathPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  public transform(path: string): string {
    return UrlPathHelperService.getUrlPath(path, this.translocoService.getActiveLang());
  }
}
