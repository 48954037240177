import { Pipe, PipeTransform } from '@angular/core';
import { Feature, Product } from '@lobos/library';
import { WeylandSteelArticle } from '../../interfaces/steel-article.model';

@Pipe({
  name: 'articleFeatureList',
})
export class ArticleFeatureListPipe implements PipeTransform {
  transform(article: WeylandSteelArticle | undefined, product: Product | null | undefined): Feature[] | undefined {
    if (!article) {
      return [];
    }

    if (!product) {
      product = article?.oProductInfo[0];
    }

    if (product && product.oFeatureClass.length > 0 && article.oFeatures) {
      return article.oFeatures.filter((feature) =>
        product!.oFeatureClass.find((featureClass) => featureClass.lngFeatureID === feature.lngFeatureID),
      );
    }

    return article.oFeatures;
  }
}
