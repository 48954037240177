import { Injectable } from '@angular/core';
import {
  AuthChangedHook,
  HookEvent,
  HookHandlerAbstract,
  HookPriority,
  PriceStore,
  RequestDebounceStore,
  ShippingAddress,
  ShippingAddressStore,
  User,
  UserStore,
} from '@lobos/library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type type = AuthChangedHook;

/**
 * This hook resets the Price-Store, whenever the user changes.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationChangedResetPricesHandler extends HookHandlerAbstract<type, Observable<User>> {
  constructor(
    private priceStore: PriceStore,
    private requestDebounceStore: RequestDebounceStore,
    private userStore: UserStore<User>,
    private shippingAddressStore: ShippingAddressStore<ShippingAddress>,
  ) {
    super();
  }

  ids(): type[] {
    return [{ type: 'AuthChangedHook' }];
  }

  override priority(): number {
    return HookPriority.HIGHEST;
  }

  override after(event: HookEvent<type, Observable<User>>, result: Observable<User>): Observable<User> {
    return super.after(event, result).pipe(
      tap(() => {
        this.priceStore.reset();
        this.requestDebounceStore.reset();
        this.userStore.reset();
        this.shippingAddressStore.reset();
      }),
    );
  }
}
