import { Injectable } from '@angular/core';
import { Article, Product, ReferenceLink, Ressource } from '@lobos/library';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { environment } from '../../../environments/environment';

export interface ImageGalleryItem {
  id: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageHelperService {
  public getProductImages(product: Product): ImageGalleryItem[] {
    let images: ImageGalleryItem[] = [];
    const resources: Ressource[] = product.oResources.filter((resource: Ressource) =>
      [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal),
    );

    if (resources) {
      images = resources.map((resource: Ressource) => ({
        id: resource.sResourceName,
        image: this.buildCloudinaryUrl(this.buildImageUrl(resource.sResourcePath)).toURL(),
      }));
    }

    if (!images.length) {
      images.push(this.addFallbackToGallery());
    }

    return images;
  }

  public getProductMainImage(product: Product): CloudinaryImage | null {
    const image = product.oResources.find(
      (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.categoryImage,
    );

    if (!image) {
      return this.returnFallbackImage();
    }

    return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourcePath));
  }

  public getArticleMainImage(article: Article): CloudinaryImage | null {
    let image: ReferenceLink | Ressource | undefined = article.oReferenceLink.find((link: ReferenceLink) => link.lngProgramID === 100180);

    if (!image) {
      const mainProduct = article.oProductInfo.find((product) => product.shtP48MainProduct === 1);

      if (mainProduct) {
        image = mainProduct.oResources?.find(
          (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.categoryImage,
        );
      } else {
        image = article.oProductInfo
          .find((product: Product) => !!product)
          ?.oResources.find((resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.categoryImage);
      }
    } else {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sDescription));
    }

    if (image) {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourcePath));
    }

    return this.returnFallbackImage();
  }

  public getArticleImages(article: Article): ImageGalleryItem[] {
    const images: ImageGalleryItem[] = [];

    const referenceLink = article.oReferenceLink.find((link: ReferenceLink) => link.lngProgramID === 100180);

    if (!images.length && article.oProductInfo.length) {
      const mainProduct = article.oProductInfo.find((mainProduct) => mainProduct.shtP48MainProduct === 1);
      if (mainProduct) {
        images.push(...this.getProductImages(mainProduct));
      } else {
        images.push(...this.getProductImages(article.oProductInfo[0]));
      }
    }

    if (referenceLink) {
      images.push({
        id: referenceLink.sLinkID,
        image: this.buildCloudinaryUrl(this.buildImageUrl(referenceLink.sDescription)).toURL(),
      });
    }

    if (!images.length) {
      images.push(this.addFallbackToGallery());
    }

    return images;
  }

  private buildImageUrl(imagePath: string): string {
    return `${environment.images.cloudinary.fetchImageUrl}/${imagePath}`;
  }

  private buildCloudinaryUrl(imageUrl: string): CloudinaryImage {
    return new CloudinaryImage(imageUrl, { cloudName: environment.images.cloudinary.cloudName }).setDeliveryType('fetch');
  }

  getProductManufacturerLogo(resources: Ressource[]): CloudinaryImage | null {
    const image = resources.find((resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.manufacturerLogo);

    if (!image) {
      return null;
    }

    return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourcePath));
  }

  getLabelImage(resources: Ressource[]): CloudinaryImage | null {
    const image = resources.find((resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.labelImages);

    if (!image) {
      return null;
    }

    return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourcePath));
  }

  getArticleManufacturerLogo(article: Article): CloudinaryImage | null {
    let image: ReferenceLink | Ressource | undefined = article.oReferenceLink.find((link: ReferenceLink) => link.lngProgramID === 1220166);

    if (!image) {
      const mainProduct = article.oProductInfo.find((product) => product.shtP48MainProduct === 1);

      if (mainProduct) {
        image = mainProduct.oResources?.find(
          (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.manufacturerLogo,
        );
      } else {
        image = article.oProductInfo[0]?.oResources?.find(
          (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.manufacturerLogo,
        );
      }
    } else {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sLinkReference));
    }

    if (image) {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourcePath));
    }

    return null;
  }

  getArticleLabelImage(article: Article): CloudinaryImage | null {
    let image: ReferenceLink | Ressource | undefined = article.oReferenceLink.find((link: ReferenceLink) => link.lngProgramID === 1220166);

    if (!image) {
      const mainProduct = article.oProductInfo.find((product) => product.shtP48MainProduct === 1);

      if (mainProduct) {
        image = mainProduct.oResources?.find(
          (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.labelImages,
        );
      } else {
        image = article.oProductInfo[0]?.oResources?.find(
          (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.labelImages,
        );
      }
    } else {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sLinkReference));
    }

    if (image) {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourcePath));
    }

    return null;
  }

  returnFallbackImage(): CloudinaryImage {
    if (environment.api.branchKey === 'W101') {
      return this.buildCloudinaryUrl('https://shop.weyland.at/images/no-image.png');
    }
    if (environment.api.branchKey === 'W102') {
      return this.buildCloudinaryUrl('https://shop.weyland.at/images/no-image.png');
    }
    return this.buildCloudinaryUrl('https://shop.weyland.at/images/no-image.png');
  }

  addFallbackToGallery(): ImageGalleryItem {
    if (environment.api.branchKey === 'W101') {
      return {
        id: '',
        image: this.buildCloudinaryUrl('https://shop.weyland.at/images/no-image.png').toURL(),
      };
    } else if (environment.api.branchKey === 'W102') {
      return {
        id: '',
        image: this.buildCloudinaryUrl('https://shop.weyland.at/images/no-image.png').toURL(),
      };
    } else {
      return {
        id: '',
        image: this.buildCloudinaryUrl('https://shop.weyland.at/images/no-image.png').toURL(),
      };
    }
  }
}
