<footer class="footer" *ngIf="footer$ | async as footer">
  <!-- {% include '../breadcrumb.html' %} -->
  <app-breadcrumb></app-breadcrumb>

  <div class="footer-inner">
    <!-- {% include '../../components/footer/footer-main.html' %} -->
    <app-footer-main [footer]="footer"></app-footer-main>
    <!-- {% include '../../components/footer/footer-notes.html' %} -->
    <app-footer-notes [footer]="footer"></app-footer-notes>
  </div>
</footer>
