import {
  buildMultiSearchQuery,
  CATALOG_CONFIG,
  CatalogConfig,
  ElasticSearchService,
  HighlightInterface,
  SearchResponse,
  SearchService,
  SearchStore,
} from '@lobos/library';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WeylandSearchService extends SearchService {
  constructor(
    private elasticSearchService: ElasticSearchService,
    private searchStore: SearchStore,
    @Inject(CATALOG_CONFIG) private catalog: CatalogConfig,
  ) {
    super(searchStore, elasticSearchService, catalog);
  }

  override search(searchTerm: string): Observable<SearchResponse<any>> {
    const query = buildMultiSearchQuery(
      searchTerm,
      10,
      this.elasticSearchService.localizedIndex('article'),
      this.elasticSearchService.localizedIndex('category'),
    );
    this.store.setLoading(true);
    return this.elasticSearchService
      .execute<SearchResponse<any>>(this.catalog.apiUrl + '/es/msearch', {
        index: this.elasticSearchService.localizedIndex('article'),
        query: query.map((entry) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        tap((response: any) => {
          const articles = response.responses[0].hits.hits.map((article: any) => article._source);
          const articleHighlights = response.responses[0].hits.hits.map((article: any): HighlightInterface => {
            return {
              id: article._id,
              highlights: article.highlight,
            };
          });
          const categories = response.responses[1].hits.hits.map((category: any) => category._source);
          const categoryHighlights = response.responses[1].hits.hits.map((category: any): HighlightInterface => {
            return {
              id: category._id,
              highlights: category.highlight,
            };
          });
          this.store.update({
            articles: articles,
            totalArticles: response.responses[0].hits.total.value,
            categories: categories,
            searchTerm: searchTerm,
            articleHighlights,
            categoryHighlights,
          });
          this.store.setLoading(false);
        }),
        catchError((error: Error) => {
          this.store.setLoading(false);
          return throwError(() => error);
        }),
      );
  }
}
