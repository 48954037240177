import { Injectable } from '@angular/core';

/**
 * Playing a sound on successful scan
 * Some hack is necessary to make this work on iOS
 *
 * @see https://stackoverflow.com/a/57547943/2797208
 */
@Injectable({
  providedIn: 'root',
})
export class ScanNotificationService {
  private readonly audio: HTMLAudioElement | undefined;

  constructor() {
    try {
      this.audio = new Audio();
    } catch (e) {
      // Audio not supported
    }
  }

  public prepare(): void {
    if (!this.audio) {
      return;
    }

    this.audio.autoplay = true;
    this.audio.volume = 0.5;
    this.audio.src =
      'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  }

  public play(): void {
    if (!this.audio) {
      return;
    }

    try {
      this.audio.src = '/assets/sound/notification.mp3';

      // Try vibrating
      navigator.vibrate(200);
    } catch (_) {
      // nothing
    }
  }
}
