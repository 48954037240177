import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, StockAvailability, StockInfo, User } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-article-availability-dialog',
  templateUrl: './article-availability-dialog.component.html',
})
export class ArticleAvailabilityDialogComponent {
  public stockAvailability$: Observable<StockAvailability>;
  protected readonly decQtyRequested: number;
  public shtStockLevel: number;
  user$: Observable<User | undefined> = this.authService.authUser$;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: any,
    protected translocoService: TranslocoService,
    private authService: AuthService,
  ) {
    this.shtStockLevel = dialogData.shtStockLevel;
    this.stockAvailability$ = dialogData.stockAvailability$;
    this.decQtyRequested = dialogData.decQtyRequested;
  }

  public evaluateStockAvailability(oStockInfo: StockInfo, _: number, bCSS: boolean): string {
    let sRetVal = '';

    if (oStockInfo.decAvailable <= 0) {
      sRetVal = bCSS ? 'unavailable' : this.translocoService.translate('availability.status.unavailable');
    } else if (oStockInfo.decAvailable > 0 && oStockInfo.decAvailable >= this.decQtyRequested) {
      sRetVal = bCSS ? 'available' : this.translocoService.translate('availability.status.available');
    } else if (oStockInfo.decAvailable > 0 && oStockInfo.decAvailable < this.decQtyRequested) {
      sRetVal = bCSS ? 'temp-unavailable' : this.translocoService.translate('availability.status.temp-unavailable');
    }
    return sRetVal;
  }
}
