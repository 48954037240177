import { Injectable } from '@angular/core';
import {
  CmsUiDynamicContentService,
  CmsUiDynamicContentStore,
  HookEvent,
  HookHandlerAbstract,
  HookPriority,
  LanguageChangedHook,
} from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

type type = LanguageChangedHook;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageChangedCmsStoresHandler extends HookHandlerAbstract<type, Observable<string>> {
  constructor(private cmsStore: CmsUiDynamicContentStore, private cmsService: CmsUiDynamicContentService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override priority(): number {
    return HookPriority.LOW;
  }

  override after(event: HookEvent<type, Observable<string>>, result: Observable<string>): Observable<string> {
    this.cmsStore.reset();

    forkJoin([this.cmsService.getHomePage(), this.cmsService.getErrorPage(), this.cmsService.getFooter(), this.cmsService.getCmsMainMenu()])
      .pipe(first(), untilDestroyed(this))
      .subscribe();

    return super.after(event, result);
  }
}
