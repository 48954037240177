import { Cart, CartHeader, HookEvent, HookHandlerAbstract, SteelCartItem, switchTap, WorkflowCreateHook } from '@lobos/library';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { first, tap } from 'rxjs/operators';

type type = WorkflowCreateHook<'CreateOrderFromOfferHook'>;

@Injectable({
  providedIn: 'root',
})
export class OrderCreateOrderFromOfferHandler extends HookHandlerAbstract<type, Observable<Cart<CartHeader, SteelCartItem>>> {
  private static readonly TRANSLOCO_SCOPE = 'cart';

  constructor(private toastr: ToastrService, private translocoService: TranslocoService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateOrderFromOfferHook' }];
  }

  override after(
    event: HookEvent<type, Observable<Cart<CartHeader, SteelCartItem>>>,
    result: Observable<Cart<CartHeader, SteelCartItem>>,
  ): Observable<Cart<CartHeader, SteelCartItem>> {
    return super.after(event, result).pipe(
      switchTap((cart) => {
        return this.translocoService
          .selectTranslate(
            'cart-service.toast-success-accept-offer',
            {
              lngOrderID: cart.oSalesMaster.lngOrderID,
            },
            OrderCreateOrderFromOfferHandler.TRANSLOCO_SCOPE,
          )
          .pipe(
            first(),
            tap((message: string) => this.toastr.success(message)),
          );
      }),
    );
  }
}
