import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AuthService,
  CartHeader,
  CartHeaderQuery,
  CartItemQuery,
  CartService,
  SteelCartItem,
  SteelCreateCartItemInterface,
} from '@lobos/library';
import { ID } from '@datorama/akita';
import { environment } from '../../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-cart-menu',
  templateUrl: './cart-menu.component.html',
  styleUrls: ['./cart-menu.component.scss'],
})
export class CartMenuComponent {
  public carts$: Observable<CartHeader[]> = this.cartHeaderQuery.selectAll();
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public loading = false;

  public activeCart$: Observable<CartHeader | undefined> = this.cartHeaderQuery.selectActive();
  public activeCartItems$: Observable<SteelCartItem[]> = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.cartItemQuery.selectCartItems(id!)),
    map((items: SteelCartItem[]) => items.sort((a, _) => (environment.shippingCostArticleIDs.includes(a.sArticleID.toString()) ? 1 : -1))),
  );
  status: boolean = false;
  quantityChangeLoading = false;

  constructor(
    private cartHeaderQuery: CartHeaderQuery<CartHeader>,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
    private cartItemQuery: CartItemQuery<SteelCartItem>,
    private translocoService: TranslocoService,
    private authService: AuthService,
  ) {}

  setActiveCart(cart: CartHeader) {
    this.cartService.changeActiveCart(cart.lngOrderID).pipe(take(1)).subscribe();
  }

  onQuantityChange(quantity: number, item: SteelCartItem) {
    const tempItem: SteelCartItem = { ...item, decBO3QuantityInput: quantity };

    this.quantityChangeLoading = true;
    this.cartService
      .updateCartItem(tempItem)
      .pipe(take(1))
      .subscribe(() => {
        this.quantityChangeLoading = false;
      });
  }

  identifyCartItem(_: any, cartItem: SteelCartItem) {
    return cartItem.shtFixedItemID;
  }

  openDeleteArticleFromCart(cartItem: SteelCartItem) {
    this.loading = true;
    this.cartService
      .deleteCartItem(cartItem)
      .pipe(take(1))
      .subscribe(() => (this.loading = false));
  }

  clickEvent() {
    this.status = !this.status;
  }
}
