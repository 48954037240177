import { Component, Input } from '@angular/core';
import { ArticleCount, Product, ViewModeType } from '@lobos/library';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent {
  @Input() product!: Product;
  @Input() articleCount!: ArticleCount[];
  @Input() viewMode: ViewModeType = 'grid';
}
