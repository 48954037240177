import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ID } from '@datorama/akita';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-cart-step-breadcrumb]',
  templateUrl: './cart-step-breadcrumb.component.html',
  styleUrls: ['./cart-step-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CartStepBreadcrumbComponent {
  @Input() lngOrderID!: number | ID;

  @Input()
  public step: number = 1;

  @Input()
  public finished: boolean = false;

  constructor(private translocoService: TranslocoService) {}

  getLocalizedUrlPath(path: string): string {
    return '/' + this.translocoService.getActiveLang() + path;
  }
}
