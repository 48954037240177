<ng-container *ngIf="shtStockLevel$ | async as shtStockLevel">
  <ng-container *ngIf="onArticlePage; else standardAvailability">
    <ng-container *ngIf="(user$ | async)?.shtRoleID !== 1 && (user$ | async)?.shtRoleID !== 2">
      <div *transloco="let t">
        <div class="margin--bottom-s">
          <strong>{{ t("shared.article-availability.availability") }}</strong>
        </div>
        <div *ngIf="stockAvailability$ | async as stockAvailability" class="article-availability">
          <div class="flex-container" *ngFor="let oStockInfo of stockAvailability.oStockInfoColl">
            <ng-container>
              <ng-container *ngIf="oStockInfo.decAvailable > 1">
                <div class="dot-container">
                  <div>
                    <div
                      class="delivery-status {{ evaluateStockAvailability(oStockInfo, shtStockLevel, true) }}"
                      title="{{ evaluateStockAvailability(oStockInfo, shtStockLevel, true) }}"
                    >
                      <div class="delivery-status-dot"></div>
                    </div>
                  </div>
                </div>
                <div class="stock-container">{{ oStockInfo.decAvailable }}&nbsp;{{ oStockInfo.sQuantityUnit }}</div>
                <div class="warehouse-container">
                  {{ oStockInfo.sStorageLocationCity }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #standardAvailability>
    <div
      [class.availabilitySpinner]="loading"
      *ngIf="shtStockLevel > 0"
      class="delivery-status default-font-sizes default-font-sizes--ultra-small {{ availableCSS }}"
      title="{{ availableHint }}"
      (click)="openAvailability($event, shtStockLevel)"
      [ngClass]="(user$ | async)?.shtRoleID === 1 || (user$ | async)?.shtRoleID === 2 ? 'margin-left-10' : ''"
    >
      <span class="delivery-status-dot"></span>
      <ng-container *ngIf="stockAvailability$ | async as oStockAvailability">
        <ng-container *ngIf="(user$ | async)?.shtRoleID !== 1 && (user$ | async)?.shtRoleID !== 2" style="margin-left: 5px">
          <ng-container *ngIf="shtStockLevel > 2">
            <span>{{ oStockAvailability.decAvailable }}&nbsp;{{ oStockAvailability.sQuantityUnit }}</span>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
