import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { UrlHelperService } from '@lobos/library';

@Pipe({
  name: 'urlFilterParams',
})
export class UrlFilterParamsPipe implements PipeTransform {
  constructor(private urlHelper: UrlHelperService) {}

  public transform(_: string, includeViewParams: boolean = true): Params {
    const queryParams: Params = this.urlHelper.getQueryParams();
    const queryFilterParams: Params = {};
    Object.keys(queryParams).forEach((key) => {
      if (!includeViewParams && key.match(/^_/)) {
        return;
      }
      queryFilterParams[key] = queryParams[key];
    });

    return queryFilterParams;
  }
}
