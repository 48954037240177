import { Component, ViewEncapsulation } from '@angular/core';
import { ID } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import {
  AuthService,
  CartHeader,
  CartService,
  FavoriteItemQuery,
  FavoriteList,
  FavoriteListQuery,
  FavoriteService,
  SteelCartItem,
  SteelCreateCartItemInterface,
  SteelFavoriteItemModel,
} from '@lobos/library';
import { WeylandFavoriteItem } from '../../../../interfaces/favorite-item.model';

@UntilDestroy()
@Component({
  selector: 'app-favorite-menu',
  templateUrl: './favorite-menu.component.html',
  styleUrls: ['./favorite-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoriteMenuComponent {
  activeFavoriteList$: Observable<FavoriteList | undefined> = this.favoriteListQuery.selectActive();
  activeFavoriteItems$: Observable<SteelFavoriteItemModel[]> = this.favoriteListQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.favoriteItemQuery.selectFavoriteItems(id!)),
  );
  favoriteLists$: Observable<FavoriteList[]> = this.favoriteListQuery.selectAll();

  isVisible = false;
  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  status: boolean = false;

  constructor(
    private favoriteListQuery: FavoriteListQuery<FavoriteList>,
    private favoriteItemQuery: FavoriteItemQuery<WeylandFavoriteItem>,
    private favoriteService: FavoriteService<FavoriteList, SteelFavoriteItemModel>,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
    private translocoService: TranslocoService,
    private authService: AuthService,
  ) {}

  setActiveFavoriteList(favoriteList: FavoriteList) {
    this.favoriteService.getFavoriteItems(favoriteList.gListID).pipe(take(1), untilDestroyed(this)).subscribe();
    this.favoriteService.setActiveFavoriteId(favoriteList.gListID);
  }

  clickEvent() {
    this.status = !this.status;
  }
}
