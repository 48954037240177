import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SteelUnit } from '@lobos/library';
import { isEqual } from 'lodash';
import { WeylandSteelArticle } from '../../interfaces/steel-article.model';

@Component({
  selector: 'app-article-unit',
  templateUrl: './article-unit.component.html',
  styleUrls: ['./article-unit.component.scss'],
})
export class ArticleUnitComponent {
  private _article!: WeylandSteelArticle;

  @Input()
  set article(value: WeylandSteelArticle) {
    this._article = value;
    if (this.onlyConfigurable) this.units = this.article.oUnitColl.filter((u) => u.shtP48Configurator === 1);
    else this.units = this.article.oUnitColl;
  }

  get article() {
    return this._article;
  }

  @Input() bLabel = true;
  @Input() selectedItem!: SteelUnit;
  @Input() onlyConfigurable = false;
  @Output() articleChange = new EventEmitter<WeylandSteelArticle>();
  units: SteelUnit[] = [];

  onSelectionChanged(unit: SteelUnit) {
    this.articleChange.emit({ ...this.article, oUnitSelected: unit });
  }

  unitCompare(u1: SteelUnit, u2: SteelUnit): boolean {
    return isEqual(u1, u2);
  }
}
