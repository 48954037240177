import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickDirective } from './click-helper/click.directive';
import { FakeSlidesDirective } from './fake-slides/fake-slides.directive';
import { HoverMenuDirective } from './hover-menu/hover-menu.directive';
import { IsBrowserDirective } from './is-browser/is-browser.directive';
import { LoadingDirective } from './loading/loading.directive';
import { OwlPreventClickDirective } from './owl-prevent-click/owl-prevent-click.directive';
import { ToggleMenuDirective } from './toggle-menu/toggle-menu.directive';

const directives: any[] = [
  OwlPreventClickDirective,
  ClickDirective,
  FakeSlidesDirective,
  LoadingDirective,
  ToggleMenuDirective,
  HoverMenuDirective,
  IsBrowserDirective,
];

@NgModule({
  declarations: directives,
  imports: [CommonModule],
  exports: directives,
})
export class DirectivesModule {}
