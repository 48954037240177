import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuantityInputComponent {
  @Input() additionalClasses: string = '';
  @Input() big: boolean = false;
  @Input() quantity = 1;
  @Input() articleId = '';
  @Input() disabled = false;
  @Input() debounceTime = 0;
  @Input() isLoading = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuantityChange = new EventEmitter();

  @ViewChild('input') input: any;

  setNewQuantity() {
    const newQuantity = parseInt(this.input.nativeElement.value);
    this.quantity = newQuantity;
    this.onQuantityChange.emit(newQuantity);
  }

  plus() {
    this.quantity += 1;
    this.onQuantityChange.emit(this.quantity);
  }

  minus() {
    if (this.quantity === 0) {
      this.onQuantityChange.emit(this.quantity);
      return;
    }
    this.quantity -= 1;
    this.onQuantityChange.emit(this.quantity);
  }
}
