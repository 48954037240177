import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT, Location } from '@angular/common';
import { Breadcrumb, BreadcrumbsService } from '@lobos/library';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  public crumbs$: Observable<Breadcrumb[]> = this.breadcrumbsService.breadcrumbs$;

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
  ) {
    router.events.subscribe((event) => {
      const backBtn = this.document.querySelector('.breadcrumb-item--back');
      if (event instanceof NavigationEnd) {
        if (event.url === '/' + this.translocoService.getActiveLang()) {
          backBtn?.classList.add('backBtnHome');
        } else {
          backBtn?.classList.remove('backBtnHome');
        }
      }
    });
  }

  back(): void {
    this.location.back();
  }
}
