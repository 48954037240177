import { Pipe, PipeTransform } from '@angular/core';
import { SteelUnit } from '@lobos/library';

@Pipe({
  name: 'selectedUnitQueryParam',
})
export class SelectedUnitQueryParamPipe implements PipeTransform {
  public transform(selectedUnit: SteelUnit): string {
    return JSON.stringify(selectedUnit);
  }
}
