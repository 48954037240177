import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LanguageHookModule, AuthHookModule, ErrorHookModule, AppHookModule } from '@lobos/library';

@NgModule({
  imports: [CommonModule, LanguageHookModule.forRoot(), AuthHookModule.forRoot(), ErrorHookModule.forRoot(), AppHookModule.forRoot()],
  providers: [],
})
export class HookTriggerModule {
  static forRoot(): ModuleWithProviders<HookTriggerModule> {
    return {
      ngModule: HookTriggerModule,
      providers: [],
    };
  }
}
