import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentMenuLevel, CmsUiDynamicContentQuery } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NavigationInterface } from '../../../../interfaces/navigation.interface';

@Component({
  selector: 'app-cms-menu',
  templateUrl: './cms-menu.component.html',
  styleUrls: ['./cms-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsMenuComponent {
  cmsMenu$: Observable<NavigationInterface[] | undefined> = this.cmsQuery.select('cmsMainMenu').pipe(
    filter((menu: CmsUiDynamicContentMenu) => !!menu),
    map((menu: CmsUiDynamicContentMenu) => this.convertCmsMenu(menu)),
  );

  constructor(private cmsQuery: CmsUiDynamicContentQuery, private translocoService: TranslocoService) {}

  private convertCmsMenu(cmsMenu: CmsUiDynamicContentMenu, level = 1): NavigationInterface[] | undefined {
    if (!cmsMenu[`level_${level}` as keyof CmsUiDynamicContentMenu]) {
      return;
    }

    const menu: NavigationInterface[] = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const entry of cmsMenu[`level_${level}` as keyof CmsUiDynamicContentMenu]!) {
      menu.push({
        title: entry.title,
        specialClass: entry.specialClass,
        url: this.getCmsUrlPath(entry),
        subNavigation: this.convertCmsMenu(entry, level + 1),
        directlink: entry.directlink,
      });
    }

    return menu;
  }

  private getCmsUrlPath(menu: CmsUiDynamicContentMenuLevel): string {
    return `/${this.translocoService.getActiveLang()}${menu.slug}`;
  }
}
