<ng-container *transloco="let t; scope: 'cart'">
  <div class="default-max-width centered" aria-label="Progress">
    <ul role="list" class="order-progress-inner">
      <ng-template
        [ngTemplateOutlet]="step === 1 ? stepCurrent : stepComplete"
        [ngTemplateOutletContext]="{
          step: 1,
          text: t('checkout.breadcrumb.cart'),
          classes: 'hidden md:block',
          url: !finished ? ['checkout/cart' | localizeUrlPathAsync | async, lngOrderID] : undefined
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="step === 1 ? stepUpcoming : step === 2 ? stepCurrent : stepComplete"
        [ngTemplateOutletContext]="{
          step: 2,
          text: t('checkout.breadcrumb.address'),
          classes: 'hidden md:block',
          url: step > 1 && !finished ? ['checkout/address' | localizeUrlPathAsync | async, lngOrderID] : undefined
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="step > 3 ? stepComplete : step < 3 ? stepUpcoming : stepCurrent"
        [ngTemplateOutletContext]="{
          step: 3,
          text: t('checkout.breadcrumb.check-and-order'),
          classes: 'hidden md:block',
          url: step > 2 && !finished ? ['checkout/confirmation' | localizeUrlPathAsync | async, lngOrderID] : undefined
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="step > 3 ? stepComplete : step < 4 ? stepUpcoming : stepCurrent"
        [ngTemplateOutletContext]="{
          step: 4,
          text: t('checkout.breadcrumb.completed'),
          classes: 'hidden ',
          url: step > 4 && !finished ? ['checkout/confirmation' | localizeUrlPathAsync | async, lngOrderID] : undefined
        }"
      ></ng-template>
    </ul>

    <ng-template #stepComplete let-url="url" let-text="text" let-classes="classes">
      <li class="order-progress-item cover" routerLinkActive="active">
        <span class="order-progress-item-count">
          <svg class="width-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <a [routerLink]="url" class="order-progress-item-description cover-link">
          {{ text }}
        </a>
      </li>
    </ng-template>

    <ng-template #stepCurrent let-url="url" let-step="step" let-text="text" let-classes="classes">
      <li class="order-progress-item cover" routerLinkActive="active">
        <span class="order-progress-item-count">{{ step }}</span>
        <a [routerLink]="url" class="order-progress-item-description cover-link">
          {{ text }}
        </a>
      </li>
    </ng-template>

    <ng-template #stepUpcoming let-url="url" let-step="step" let-text="text" let-classes="classes">
      <li class="order-progress-item cover upcoming-steps" routerLinkActive="active">
        <span class="order-progress-item-count">{{ step }}</span>
        <a [routerLink]="url" class="order-progress-item-description cover-link">
          {{ text }}
        </a>
      </li>
    </ng-template>
  </div>
</ng-container>
