import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Article,
  Category,
  createLocalSearchString,
  GoogleTagManagerHelper,
  LocalSearchString,
  LocalSearchStringQuery,
  LocalSearchStringService,
  SearchEvent,
  SearchQuery,
  SearchService,
  SelectItemEvent,
  TrackClass,
  TrackMethod,
} from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { WeylandSteelArticle } from '../../../interfaces/steel-article.model';

@TrackClass<HeaderSearchComponent, SearchEvent>({
  provide: (self: HeaderSearchComponent) =>
    combineLatest([self.articles$, self.totalArticles$, self.searchQuery.selectLoading()]).pipe(
      debounceTime(500),
      filter(([_, _2, isSearching]: [Article[], number, boolean]) => !isSearching && self.searchForm.value.searchInput),
      map(([_, totalHits]: [Article[], number, boolean]) => ({
        name: 'search',
        payload: {
          header: true,
          term: self.searchForm.value.searchInput,
          result_count: totalHits,
        },
      })),
    ),
})
@UntilDestroy()
@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnInit {
  mobileOpen: boolean = false;

  isOpen = false;
  isMobile: boolean = false;
  articles$: Observable<WeylandSteelArticle[]> = this.searchQuery.articles$;
  categories$: Observable<Category[]> = this.searchQuery.categories$;
  pages$: Observable<any[]> = this.searchQuery.pages$;
  totalArticles$: Observable<number> = this.searchQuery.totalArticles$;
  searchForm = new UntypedFormGroup({
    searchInput: new UntypedFormControl(''),
  });
  searchTermHistory$: Observable<LocalSearchString[]> = this.localSearchStringQuery.selectAll({ limitTo: 5 });
  private wasInside = false;

  constructor(
    private searchService: SearchService,
    private searchQuery: SearchQuery<WeylandSteelArticle>,
    private router: Router,
    private translocoService: TranslocoService,
    private localSearchStringService: LocalSearchStringService<LocalSearchString>,
    private localSearchStringQuery: LocalSearchStringQuery<LocalSearchString>,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @HostListener('click')
  clickedInside() {
    this.isOpen = true;
    this.wasInside = true;
    if (window.screen.width < 768) {
      this.document.body.style.overflow = 'hidden';
    }
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isOpen = false;
      if (window.screen.width < 768) {
        this.document.body.style.overflow = 'initial';
      }
    }
    this.wasInside = false;
  }

  ngOnInit(): void {
    this.searchForm.controls['searchInput'].valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(400),
        tap((searchString: string) => {
          this.isOpen = true;
          if (searchString.length < 3) {
            this.searchService.resetStore();
          }
        }),
        filter((searchString) => searchString.length > 2),
        switchMap((searchString) => this.searchService.search(searchString)),
      )
      .subscribe();

    if (window.screen.width <= 768) {
      this.isMobile = true;
    }
  }

  searchSubmit() {
    const searchString: string = this.searchForm.controls['searchInput'].value;
    if (searchString.length < 3) {
      return;
    }
    if (
      this.localSearchStringQuery.getAll({
        filterBy: ({ searchTerm }) => searchTerm === searchString,
      }).length === 0
    ) {
      this.localSearchStringService.add(createLocalSearchString({ searchTerm: searchString }));
    }
    this.isOpen = false;
    this.router.navigate([this.translocoService.getActiveLang() + '/search', searchString]);
  }

  searchByHistorySearchTerm(event: Event, searchTerm: string) {
    event.stopPropagation();
    this.searchForm.controls['searchInput'].setValue(searchTerm);
  }

  closeOnClick(e: Event) {
    e.stopPropagation();
    this.isOpen = false;
    if (window.screen.width < 768) {
      this.document.body.style.overflow = 'initial';
    }
  }

  @TrackMethod<SelectItemEvent>({
    provide: ([_, article]: any, self: HeaderSearchComponent) => {
      return {
        name: 'select_item',
        payload: {
          ecommerce: {
            item_list_id: 'search_header',
            item_list_name: self.searchForm.value.searchInput,
            items: [GoogleTagManagerHelper.articleToItem(article)],
          },
        },
      };
    },
  })
  selectArticle(e: any, _: Article) {
    this.closeOnClick(e);
  }
}
