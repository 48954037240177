import { Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  CartHeader,
  SteelCartItem,
  FavoriteList,
  SteelCreateCartItemInterface,
  SteelFavoriteItemModel,
  FavoriteService,
  CartService,
} from '@lobos/library';

@Component({
  selector: 'app-favorite-item',
  templateUrl: './favorite-item.component.html',
  styleUrls: ['./favorite-item.component.scss'],
})
export class FavoriteItemComponent {
  @Input() favoriteItem!: SteelFavoriteItemModel;

  constructor(
    private favoriteService: FavoriteService<FavoriteList, SteelFavoriteItemModel>,
    private translocoService: TranslocoService,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
  ) {}

  async onQuantityChange(favoriteItem: SteelFavoriteItemModel, quantity: number) {
    await this.favoriteService.updateFavoriteItem({ ...favoriteItem, decQuantity: quantity }).toPromise();
  }

  async onAddArticleToCartClick(favoriteItem: SteelFavoriteItemModel) {
    if (favoriteItem.decQuantity > 0) {
      const selectedUnit = favoriteItem.oArticle.oUnitColl.find((unit) => unit.sQuantityUnitInput === favoriteItem.sB03QuantityUnitInput);
      await this.cartService
        .createCartItem({
          decQuantity: favoriteItem.decQuantity,
          sArticleID: favoriteItem.oArticle.sArticleID as string,
          sArticleName: favoriteItem.oArticle.sName,
          sQuantityUnit: favoriteItem.oArticle.sQuantityUnitSales,
          oArticle: favoriteItem.oArticle,
          decBO3Factor1: selectedUnit!.decBO3Factor1,
          decBO3Factor2: selectedUnit!.decBO3Factor2,
          decBO3Factor3: selectedUnit!.decBO3Factor3,
          sBO3QuantityUnitInput: favoriteItem.sB03QuantityUnitInput,
          sItemText: '',
          oSteelConfiguration: undefined,
        })
        .toPromise();
    }
  }

  async deleteFavoriteItem(favoriteItem: SteelFavoriteItemModel) {
    await this.favoriteService.deleteFavoriteItem(favoriteItem).toPromise();
  }
}
