<ng-container *transloco="let t; scope: 'checkout'">
  <ng-container
    *ngIf="
      item?.oSteelConfiguration?.oFigureSelected &&
      (item.oSteelConfiguration.oFigureSelected.decFrontAngle || item.oSteelConfiguration.oFigureSelected.decRearAngle)
    "
  >
    <p>{{ t("checkout.cart-overview.figure-image") }} {{ item.oSteelConfiguration.oFigureSelected.sFigureID }}</p>
    <p *ngIf="item.oSteelConfiguration.oFigureSelected.decFrontAngle">
      {{ t("checkout.cart-overview.front-angle", { degree: item.oSteelConfiguration.oFigureSelected.decFrontAngle }) }}
    </p>
    <p *ngIf="item.oSteelConfiguration.oFigureSelected.decRearAngle">
      {{ t("checkout.cart-overview.rear-angle", { degree: item.oSteelConfiguration.oFigureSelected.decRearAngle }) }}
    </p>
  </ng-container>
</ng-container>
