<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="oPrice$ | async as oPrice">
    <ng-container *ngIf="oPrice.oPriceScaleInfos && oPrice.oPriceScaleInfos.length > 0">
      <div class="relative margin--bottom-s">
        <strong>{{ t("shared.bulk-price.bulk-price-title") }}</strong>
      </div>
      <table>
        <tr *ngFor="let item of oPrice.oPriceScaleInfos">
          <td>
            {{ t("shared.bulk-price.from") }}
            {{ item.decQuantityFrom }}
            {{ item.sQuantityUnit || oPrice.sQuantityUnit }}
          </td>
          <td>
            {{ item.decPriceNet | translocoCurrency }}
            {{ t("shared.bulk-price.per") }}
            {{ item.lngSalesPriceUnit || oPrice.lngSalesPriceUnit }}
            {{ item.sQuantityUnit || oPrice.sQuantityUnit }}
          </td>
        </tr>
      </table>
    </ng-container>
  </ng-container>
</ng-container>
