import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointService } from '../../../services/ui/breakpoint.service';

@UntilDestroy()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document: Document, public breakpointService: BreakpointService) {}

  ngOnInit(): void {
    this.breakpointService.tablet.pipe(untilDestroyed(this)).subscribe((isTablet: boolean) => {
      if (isTablet) {
        this.document.body.classList.add('touchevents');
      } else {
        this.document.body.classList.remove('touchevents');
      }
    });
  }
}
