import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MenuQuery } from '../../services/menu/menu.query';
import { MenuState, MenuStore } from '../../services/menu/menu.store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  isSticky: boolean = false;
  isOpen$ = this.menuQuery.isOpen$;

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.isSticky = window.pageYOffset >= 50;
  }

  constructor(@Inject(DOCUMENT) private _document: Document, private menuQuery: MenuQuery, private menuStore: MenuStore) {}

  toggleMenu() {
    this.menuStore.update((currentState: MenuState) => ({ open: !currentState.open }));
  }
}
