import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { SettingsInterface } from '../model/settings.interface';
import { SettingsStore } from '../store/settings.store';

@Injectable({ providedIn: 'root' })
export class SettingsService<T extends SettingsInterface> {
  constructor(private store: SettingsStore<T>, private cookieService: CookieService) {}

  update(settings: Partial<T>) {
    this.setCookie(settings);
    return this.store.update(settings);
  }

  /**
   * @todo if needed, merge cookie and partials settings for incremental update
   */
  setCookie(settings: Partial<T>): void {
    // Cookie expires after 365 days
    const cookieExpire = new Date();
    cookieExpire.setDate(cookieExpire.getDate() + 365);

    this.cookieService.put('settings', JSON.stringify(settings), { expires: cookieExpire });
  }

  getFromCookie(): T | undefined {
    return this.cookieService.hasKey('settings') ? JSON.parse(this.cookieService.get('settings')) : undefined;
  }
}
