<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="activeFavoriteItems$ | async as items">
    <ng-container *ngIf="activeFavoriteItems$ | async as items">
      <div class="mobile-nav-header">
        <div class="bold"><i class="icon-heart-filled"></i> {{ t("core.header-nav.favorite-menu.favlist") }}</div>
        <i class="icon-close" (click)="closeMobileNav.emit()"></i>
      </div>

      <div class="favorite-content">
        <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, activeFavoriteList: activeFavoriteList$ | async } as context">
          <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
            <div class="favorite-menu-item">
              <mat-form-field>
                <select matNativeControl [ngModel]="context.activeFavoriteList" (ngModelChange)="setActiveFavoriteList($event)">
                  <option disabled>{{ t("core.header-nav.favorite-menu.choose-favlist") }}</option>
                  <option class="optionClass" *ngFor="let list of favoriteLists$ | async" [ngValue]="list">
                    {{ t("core.header-nav.favorite-menu.favlist") }}: {{ list.sListname }}
                  </option>
                </select>
              </mat-form-field>
            </div>

            <div class="favorite-menu-item">
              <a [routerLink]="'/profile/favorites' | localizeUrlPath" class="mini-cart-item-justify">
                <span> {{ t("core.header-nav.favorite-menu.manage-favlist") }} </span>

                <i class="icon-setting"></i>
              </a>
            </div>

            <div class="favorite-menu-item">
              <a [routerLink]="'/profile/favorites/new/' | localizeUrlPath" class="mini-cart-item-justify">
                <span> {{ t("core.header-nav.favorite-menu.add-favlist") }} </span>

                <i class="icon-plus"></i>
              </a>
            </div>

            <div class="favorite-article-list">
              <ng-container *ngFor="let item of items">
                <div class="favorite-article-item">
                  <div class="item-upper-row">
                    <div class="article-image">
                      <app-image [image]="item.oArticle | articleMainImage"></app-image>
                    </div>

                    <div>
                      <div class="article-name">
                        <a [routerLink]="['/article/' + item.oArticle.sArticleID | localizeUrlPath]"
                          ><strong>{{ item.oArticle.sName }}</strong>
                        </a>
                      </div>
                      <p class="article-number">
                        {{ t("core.header-nav.cart-menu.article-number") }}: <strong>{{ item.oArticle.sArticleID }}</strong>
                      </p>
                    </div>

                    <div>
                      <a class="cta-icons" (click)="onAddArticleToCartClick(item)">
                        <i class="icon-cart"></i>
                      </a>
                      <br /><br />
                      <a class="cta-icons" (click)="deleteFavoriteItem(item)">
                        <i class="icon-trash"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="item-lower-row">
                  <div class="input-section">
                    <app-quantity-input [quantity]="item.decQuantity" (onQuantityChange)="onQuantityChange(item, $event)">
                    </app-quantity-input>
                  </div>

                  <div class="article-price">
                    <app-article-price [article]="item.oArticle"></app-article-price>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="items.length < 1" class="articles-in-cart">{{ t("core.header-nav.favorite-menu.add-to-favlist-message") }}</div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #notLoggedIn>
      <div class="favorite-article-list">
        <ng-container *ngFor="let item of items">
          <div class="favorite-article-item">
            <div class="item-upper-row">
              <div class="article-image">
                <app-image [image]="item.oArticle | articleMainImage"></app-image>
              </div>

              <div>
                <div class="article-name">
                  <a [routerLink]="['/article/' + item.oArticle.sArticleID | localizeUrlPath]"
                    ><strong>{{ item.oArticle.sName }}</strong>
                  </a>
                </div>
                <p class="article-number">
                  {{ t("core.header-nav.cart-menu.article-number") }}: <strong>{{ item.oArticle.sArticleID }}</strong>
                </p>
              </div>

              <div>
                <a class="cta-icons" (click)="onAddArticleToCartClick(item)">
                  <i class="icon-cart"></i>
                </a>
                <br /><br />
                <a class="cta-icons" (click)="deleteFavoriteItem(item)">
                  <i class="icon-trash"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="item-lower-row">
            <div class="input-section">
              <app-quantity-input [quantity]="item.decQuantity" (onQuantityChange)="onQuantityChange(item, $event)"></app-quantity-input>
            </div>

            <div class="article-price">
              <app-article-price [article]="item.oArticle"></app-article-price>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="items.length < 1" class="articles-in-cart">{{ t("core.header-nav.favorite-menu.add-or-login-message") }}</div>
    </ng-template>
  </ng-container>
</ng-container>
