<div *transloco="let t">
  <ng-container *ngIf="article && article.oProductInfo.length">
    <i
      class="{{ emptySymbol }}"
      *ngIf="(isFavorite$ | async) === false"
      (click)="addArticleToFavoriteList(article)"
      matTooltip="{{ t('shared.ui-is-favorite.tooltip-add') }}"
      [matTooltipClass]="'tooltip-main'"
      [matTooltipPosition]="'above'"
    >
    </i>
    <i
      class="{{ fullSymbol }}"
      *ngIf="isFavorite$ | async"
      (click)="removeArticleFromFavoriteList(article)"
      matTooltip="{{ t('shared.ui-is-favorite.tooltip-remove') }}"
      [matTooltipClass]="'tooltip-main'"
      [matTooltipPosition]="'above'"
    >
    </i>
  </ng-container>
</div>
