<div class="scanner" [class.ready]="ready$ | async" [class.scan-complete]="articles">
  <div class="scanner-preview">
    <div #preview class="scanner-container"></div>

    <mat-spinner [diameter]="30" *ngIf="(ready$ | async) === false"></mat-spinner>
    <div *ngIf="(ready$ | async) === true" class="laser"></div>
  </div>

  <div class="scanner-result text-centered" *ngIf="articles">
    <ng-container *transloco="let t; scope: 'scanner'">
      <app-scan-result [articles]="articles"></app-scan-result>

      <button (click)="rescan()" class="btn btn--small btn--active">
        {{ t("scanner.ui-scanner.button.rescan") }}
      </button>

      <br />

      <button (click)="stop()" class="btn btn--small">
        {{ t("scanner.ui-scanner.button.stop") }}
      </button>
    </ng-container>
  </div>
</div>
