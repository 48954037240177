import { Injectable } from '@angular/core';
import { EntityState, Store, StoreConfig } from '@datorama/akita';
import { AlternateVersionInterface } from '../model/alternate-version.interface';
import { AlternateVersionService } from '../services/alternate-version.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AlternateVersionState<T extends AlternateVersionInterface> extends EntityState<T> {
  versions: T[];
}

export function createInitialState<T extends AlternateVersionInterface>(): AlternateVersionState<T> {
  return {
    versions: AlternateVersionService.defaultVersions as T[],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'alternate-version' })
export class AlternateVersionStore<T extends AlternateVersionInterface> extends Store<AlternateVersionState<T>> {
  constructor() {
    super(createInitialState<T>());
  }
}
