import { ErrorService, InjectorClass } from '@lobos/library';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { AlternateVersionInterface } from '../model/alternate-version.interface';
import { AlternateVersionService } from '../services/alternate-version.service';

interface DecoratorOptions<T> {
  provide: (caller: T) => Observable<AlternateVersionInterface[]>;
}

/**
 * Class decorator for alternate versions
 * If no versions are `provided`, it will fall back a simple language replacement in the current route.
 */
export function AlternateVersion<T>(options?: DecoratorOptions<T>): ClassDecorator {
  return (constructor: any): void => {
    /**
     * Subject to manage unsubscription
     */
    let destroyed: Subject<void> = new Subject<void>();

    const originalPageEnterEvent: () => any = constructor.prototype.ngOnInit;
    const originalPageExitEvent: () => any = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnInit = function (...args: any[]): void {
      destroyed = new Subject<void>();
      const alternateVersionService: AlternateVersionService<AlternateVersionInterface> | undefined =
        InjectorClass.inject<AlternateVersionService<AlternateVersionInterface>>(AlternateVersionService);
      const errorService: ErrorService | undefined = InjectorClass.inject<ErrorService>(ErrorService);

      if (options?.provide) {
        options
          .provide(this)
          .pipe(
            takeUntil(destroyed),
            tap((versions: AlternateVersionInterface[]) => alternateVersionService!.update(versions)),
            catchError((error: Error) => of(errorService!.add({ label: 'AlternateVersion.ngOnInit()', ...error, error: error }))),
          )
          .subscribe();
      } else {
        alternateVersionService!.update(alternateVersionService!.getSimple());
      }

      originalPageEnterEvent && typeof originalPageEnterEvent === 'function' && originalPageEnterEvent.apply(this, args as any);
    };

    constructor.prototype.ngOnDestroy = function (...args: any[]): void {
      destroyed.next();
      destroyed.complete();

      originalPageExitEvent && typeof originalPageExitEvent === 'function' && originalPageExitEvent.apply(this, args as any);
    };
  };
}
