import { Pipe, PipeTransform } from '@angular/core';
import { P48APIRoutingDetail } from '@lobos/library';
import { ConfigForm } from 'src/app/interfaces/config-form.interface';

@Pipe({
  name: 'isProcessingItemChecked',
})
export class SteelProcessingItemCheckedPipe implements PipeTransform {
  public transform(processingItem: P48APIRoutingDetail, configForm: ConfigForm): boolean {
    return !!configForm?.processingItemInput.find(
      (item: P48APIRoutingDetail) => item.sBO3SteelProcessingArticleID === processingItem.sBO3SteelProcessingArticleID,
    );
  }
}
