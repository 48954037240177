<ng-container *transloco="let t; scope: 'catalog'">
  <ng-container *ngIf="viewMode === 'grid'">
    <a [routerLink]="product.sUrlPath | urlPath" [queryParams]="product.sUrlPath | urlFilterParams" class="card-product-top">
      <div class="card-product-top-image">
        <app-image [image]="product | productMainImage"></app-image>
      </div>

      <div class="card-product-list-info">
        <div class="default-font-sizes default-font-sizes--ultra-small card-product-discount">
          {{ product | articleCount : articleCount }}
        </div>

        <div
          *ngIf="(product | articleCount : articleCount) > 1"
          class="card-product-configuration default-font-sizes default-font-sizes--ultra-small text-centered"
        >
          {{ t("catalog.product-item.versions") }}
        </div>
      </div>
    </a>

    <div class="relative card-product-bottom">
      <div class="flex card-product-description flex--d-column flex--jc-space-between">
        <div>
          <a
            [routerLink]="product.sUrlPath | urlPath"
            [queryParams]="product.sUrlPath | urlFilterParams"
            class="card-product-description-top"
          >
            <div>
              <div class="default-font-sizes default-font-sizes--ultra-small card-product-discount">
                <ng-container *ngIf="articleCount; else productArticleCount">
                  {{ product | articleCount : articleCount }} {{ t("catalog.product-item.article") }}
                </ng-container>

                <ng-template #productArticleCount> {{ product.oArticles.length }} {{ t("catalog.product-item.article") }} </ng-template>
              </div>
              <div class="default-font-sizes bold">
                {{ product.sTitle }}
              </div>

              <div class="default-font-sizes--very-small margin--top-m grid-description" [innerHtml]="product.sDescription"></div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="card-product-description-bottom">
      <a
        [routerLink]="product.sUrlPath | urlPath"
        [queryParams]="product.sUrlPath | urlFilterParams"
        class="btn text-centered defaul-font-sizes"
        >{{ t("catalog.product-item.product-button") }}</a
      >
    </div>
  </ng-container>

  <ng-container *ngIf="viewMode === 'list'" class="list-container">
    <a [routerLink]="product.sUrlPath | urlPath" [queryParams]="product.sUrlPath | urlFilterParams" class="card-product-top">
      <div class="card-product-top-image">
        <app-image [image]="product | productMainImage"></app-image>
      </div>

      <div class="card-product-list-info">
        <div class="list-view default-font-sizes default-font-sizes--ultra-small card-product-discount">
          {{ product | articleCount : articleCount }}
        </div>

        <div
          *ngIf="(product | articleCount : articleCount) > 1"
          class="card-product-configuration default-font-sizes default-font-sizes--ultra-small text-centered"
        >
          {{ t("catalog.product-item.versions") }}
        </div>
      </div>
    </a>

    <div class="relative card-product-bottom">
      <div class="flex card-product-description flex--d-column flex--jc-space-between">
        <div>
          <a
            [routerLink]="product.sUrlPath | urlPath"
            [queryParams]="product.sUrlPath | urlFilterParams"
            class="card-product-description-top"
          >
            <div>
              <div class="default-font-sizes default-font-sizes--ultra-small card-product-discount">
                <ng-container *ngIf="articleCount; else productArticleCount">
                  {{ product | articleCount : articleCount }} {{ t("catalog.product-item.article") }}
                </ng-container>

                <ng-template #productArticleCount> {{ product.oArticles.length }} {{ t("catalog.product-item.article") }} </ng-template>
              </div>
              <div class="default-font-sizes bold">
                {{ product.sTitle }}
              </div>

              <div class="default-font-sizes--very-small margin--top-m grid-description" [innerHtml]="product.sDescription"></div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="list-view card-product-description-bottom">
      <a
        [routerLink]="product.sUrlPath | urlPath"
        [queryParams]="product.sUrlPath | urlFilterParams"
        class="btn text-centered defaul-font-sizes"
        >{{ t("catalog.product-item.product-button") }}</a
      >
    </div>
  </ng-container>
</ng-container>
