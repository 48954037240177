import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Cart, CartHeader, CartItem, HookEvent, HookHandlerAbstract, HookPriority, WorkflowCreateHook } from '@lobos/library';
import { CartTypeMismatchModalComponent } from '../../../components/cart-type-mismatch-modal/cart-type-mismatch-modal.component';
import { CartErrorTypeMismatch } from '../../../interfaces/cart-errors';
import { WeylandSteelCartItem } from '../../../interfaces/cart-item.model';
import { WeylandCartHeader } from '../../../interfaces/cart-header.model';
import { WeylandCartService } from '../../../services/cart/cart.service';
import { WeylandSteelCreateCartItem } from '../../../interfaces/steel-create-cart-item.interface';

type type = WorkflowCreateHook<'CreateCartItemHook'>;

/**
 * This hooks catches the error code 'cart-type-mismatch' and offers
 * a modal, to resolve that issue
 */
@Injectable({
  providedIn: 'root',
})
export class CartTypeMismatchModal extends HookHandlerAbstract<type, Observable<Cart<CartHeader, CartItem>>> {
  constructor(private cartService: WeylandCartService, private dialog: MatDialog) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateCartItemHook' }];
  }

  override priority(): number {
    return HookPriority.LOW;
  }

  override after(
    event: HookEvent<type, Observable<Cart<WeylandCartHeader, WeylandSteelCartItem>>>,
    result: Observable<Cart<CartHeader, CartItem>>,
  ): Observable<Cart<CartHeader, CartItem>> {
    if (!event.params?.length) {
      return super.after(event, result);
    }

    const item: WeylandSteelCartItem = event.params[0];
    return result.pipe(
      catchError((error: CartErrorTypeMismatch) => (error.code === 'cart-type-mismatch' ? this.showResolveModal(item) : throwError(error))),
    );
  }

  showResolveModal(item: WeylandSteelCartItem): Observable<Cart<WeylandCartHeader, WeylandSteelCartItem>> {
    return this.dialog
      .open(CartTypeMismatchModalComponent, { data: { item } })
      .beforeClosed()
      .pipe(
        switchMap((result?: { create?: boolean }) =>
          iif(
            // user chose not to create new cart
            () => !result?.create,
            // true
            of({ oSalesItemList: [item] } as Cart<WeylandCartHeader, WeylandSteelCartItem>),
            // false
            this.cartService
              .createNewEmptyCart(item.oArticle.shtPL1P48ProductTypeID)
              .pipe(switchMap(() => this.cartService.createCartItem(item as WeylandSteelCreateCartItem))),
          ),
        ),
      );
  }
}
