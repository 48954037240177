import { Component, Input } from '@angular/core';
import { Footer } from '@lobos/library';

@Component({
  selector: 'app-footer-notes',
  templateUrl: './footer-notes.component.html',
  styleUrls: ['./footer-notes.component.scss'],
})
export class FooterNotesComponent {
  @Input() footer!: Footer;
}
