<ng-container *transloco="let t; scope: 'shared'">
  <div *ngIf="!showMyContainer">
    <div class="flex flex--jc-space-between">
      <h1 class="headline-second">{{ t("shared.login-dialog.title") }}</h1>
      <button mat-icon-button mat-dialog-close>
        <i class="icon-close"></i>
      </button>
    </div>

    <form *ngIf="form" [formGroup]="form" (ngSubmit)="login()" autocomplete="off">
      <div class="default-font-sizes default-font-sizes--small default-text-spacing margin--bottom-l">
        <p [innerHTML]="t('shared.login-dialog.welcome-text')"></p>
      </div>

      <mat-form-field class="margin--top-m" appearance="fill">
        <mat-label>{{ t("shared.login-dialog.login-username") }}</mat-label>
        <input matInput type="text" name="username" formControlName="username" id="username" required />
      </mat-form-field>

      <mat-form-field class="margin--top-m" appearance="fill">
        <mat-label>{{ t("shared.login-dialog.login-password") }}</mat-label>
        <input matInput type="password" name="password" formControlName="password" id="password" required />
      </mat-form-field>

      <div *ngIf="error" class="text-left login-error" role="alert">
        <span class="block sm:inline">{{ error | transloco }}</span>
      </div>

      <div
        class="flex form default-font-sizes default-font-sizes--small flex--jc-space-between margin--top-s margin--bottom-s layout-line-split-inset"
      ></div>

      <button
        type="submit"
        class="btn btn--wide btn--small btn--active layout-line-split-push-top layout-line-split-inset margin--bottom-l"
        [class.spinner]="loading"
        [disabled]="loading || form.invalid"
      >
        {{ t("shared.login-dialog.login-btn") }}
      </button>
    </form>
  </div>

  <button (click)="showMyContainer = !showMyContainer">
    <span class="link-to-forgot" *ngIf="showMyContainer">
      {{ t("shared.login-dialog.back") }}
    </span>
    <span class="link-to-forgot" *ngIf="!showMyContainer">
      {{ t("shared.login-dialog.forgot-password") }}
    </span>
  </button>

  <div *ngIf="showMyContainer">
    <app-forgot-password></app-forgot-password>
  </div>
</ng-container>
