import { Injectable } from '@angular/core';
import { Configuration, Label, LabelData, PaddingBoxInterface, RendererAbstract, Template } from '@lobos/label-print';
import * as QRCode from 'qrcode';
import { WeylandCustomFieldsInterface } from '../model/weyland-custom-fields.interface';

@Injectable({
  providedIn: 'root',
})
export class Weyland3x8QrRenderer extends RendererAbstract {
  public template(): Template {
    return {
      id: 'weyland3x8',
      title: 'profile.label-print.template.weyland3x8_qr.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 34.4 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 8 },
    };
  }

  public override prepare(labels: Label[], configuration: Configuration): void {
    this.configuration = configuration;

    this.rawLabels = labels;

    this.labels = [];
    // attach empty offset labels
    this.labels = this.labels.concat(Array(+configuration.offset).fill({ type: 'empty' } as Label));
    // this will repeat the labels as often, as `amount` is set
    this.rawLabels
      .filter((rawLabel: Label) => rawLabel.print && rawLabel.amount > 0)
      .forEach((rawLabel: Label, _: number) => {
        this.labels = this.labels.concat(Array(rawLabel.amount).fill(rawLabel));
      });

    this.jspdf.startDocument(this.orientation, this.format);
  }

  /**
   * Südo 3x8
   *
   * We try to layout the following:
   *
   *
   */

  protected async drawData(label: Label, x: number, y: number): Promise<void> {
    const labelData: LabelData = label.data as WeylandCustomFieldsInterface;
    let paddingBox: PaddingBoxInterface;

    // Image
    paddingBox = this.addPadding(x, y, this.boxWidth * (1 / 4), this.boxHeight * (4 / 5) - this.jspdf.getTextDimensions(labelData.date).h);
    await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);

    //date
    paddingBox = this.addPadding(x, y + this.boxHeight * (4 / 5), this.boxWidth * (1 / 4), this.boxHeight * (1 / 5));
    this.jspdf.drawText(labelData.date, paddingBox.x, paddingBox.y, paddingBox.width, 6, 1, 'normal');

    // QR Code
    paddingBox = this.addPadding(
      x + this.boxWidth * (3 / 4),
      y,
      this.boxWidth * (1 / 4),
      this.boxHeight * (4 / 5) - this.jspdf.getTextDimensions(labelData.sku!).h,
    );
    await this.addQrCode(labelData.sku!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);

    //logo oder Kundennummer
    paddingBox = this.addPadding(
      x + this.boxWidth * (3 / 4),
      y + this.boxHeight * (4 / 5),
      this.boxWidth * (1 / 4),
      this.boxHeight * (1 / 5),
    );
    await this.addImage(labelData.logoPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    // paddingBox = this.addPadding(
    //   x + this.boxWidth * (3 / 4),
    //   y + this.boxHeight * (4 / 5),
    //   this.boxWidth * (1 / 4),
    //   this.boxHeight * (1 / 5),
    // );
    // this.jspdf.drawText(labelData.customFields?.customerArticleID, paddingBox.x, paddingBox.y, paddingBox.width, 6, 1, 'bold');

    //article number
    paddingBox = this.addPadding(x + this.boxWidth * (1 / 4), y, this.boxWidth * (1 / 2), this.boxHeight * (1 / 6));
    this.jspdf.drawText(labelData.sku!, paddingBox.x, paddingBox.y, paddingBox.width, y < 7 ? 5 : 8, 1, 'bold');

    //article type
    paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 4),
      y + this.boxHeight * (1 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );

    // Title
    paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 4),
      y + this.boxHeight * (2 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(labelData.title, paddingBox.x, paddingBox.y, paddingBox.width, y < 7 ? 5 : 8, 2, 'normal');

    // exkl. MwSt.

    paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 4),
      y + this.boxHeight * (y < 7 ? 3.5 / 6 : 4 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText('exkl. MwSt.', paddingBox.x, paddingBox.y, paddingBox.width, 5, 1, 'normal');

    // Amount / Unit
    paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 4),
      y + this.boxHeight * (5 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );

    this.jspdf.drawText(`${labelData.price} / ${labelData.unit}`, paddingBox.x, paddingBox.y, paddingBox.width, 6, 1, 'normal', {
      baseline: 'bottom',
    });
  }

  async addQrCode(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    if (!code) {
      return;
    }

    await this.jspdf.drawImage(await QRCode.toDataURL(code), x, y, maxWidth, maxHeight, undefined, 'right');
  }
}
