<div *transloco="let t">
  <mat-form-field>
    <mat-label *ngIf="bLabel">
      {{ t("shared.ui-article-unit.article-unit.unit") }}
    </mat-label>
    <mat-select
      [ngModel]="selectedItem ? selectedItem : article.oUnitSelected"
      (ngModelChange)="onSelectionChanged($event)"
      [disabled]="units.length === 1"
      [compareWith]="unitCompare"
    >
      <mat-option *ngFor="let oUnit of units" [value]="oUnit">
        <ng-container *ngIf="oUnit.shtP48Configurator; else noConfigurator">
          {{ t("shared.ui-article-unit.article-unit.configure") }}
        </ng-container>
        <ng-template #noConfigurator>
          <ul class="separated-list">
            <li>{{ oUnit.sP48QuantityUnitWebshop ? oUnit.sP48QuantityUnitWebshop : oUnit.sQuantityUnitInputName }}</li>
            <li *ngIf="oUnit.sBO3Title1">{{ oUnit.decBO3Factor1 + " " + oUnit.sBO3Title1 }}</li>
            <li *ngIf="oUnit.sBO3Title2">{{ oUnit.decBO3Factor2 + " " + oUnit.sBO3Title2 }}</li>
            <li *ngIf="oUnit.sBO3Title3">{{ oUnit.decBO3Factor3 + " " + oUnit.sBO3Title3 }}</li>
          </ul>
        </ng-template>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
