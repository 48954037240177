import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMainComponent {
  public headerBgLink = '/assets/images/header-bg_' + environment.api.branchKey + '.jpg';
  public logoLink = '/assets/images/logo_' + environment.api.branchKey + '.png';

  @Output() toggelMenu = new EventEmitter();
}
