import { Injectable } from '@angular/core';
import { EntityState, Store, StoreConfig } from '@datorama/akita';
import { SettingsInterface } from '../model/settings.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SettingsState<T extends SettingsInterface> extends EntityState<T> {
  language: string;
}

function createInitialState<T extends SettingsInterface>(): SettingsState<T> {
  return {} as T;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'settings' })
export class SettingsStore<T extends SettingsInterface> extends Store<SettingsState<T>> {
  constructor() {
    super(createInitialState<T>());
  }
}
