<div class="quantity-select {{ additionalClasses }}{{ big ? ' quantity-select--big' : '' }}">
  <button
    [disabled]="disabled || isLoading"
    class="quantity-select-button"
    [class.hidden]="disabled"
    debounceClick
    (debounceClick)="minus()"
    [debounceTime]="debounceTime"
    [ngClass]="isLoading ? 'cursor-wait ' : ''"
  >
    <i class="icon-minus"></i>
  </button>

  <input
    #input
    [id]="articleId"
    (change)="setNewQuantity()"
    [value]="quantity"
    [disabled]="disabled"
    [class.bg-transparent]="disabled"
    type="number"
    name="quantity"
  />

  <button
    [disabled]="disabled || isLoading"
    class="quantity-select-button"
    [class.hidden]="disabled"
    debounceClick
    (debounceClick)="plus()"
    [debounceTime]="debounceTime"
    [ngClass]="isLoading ? 'cursor-wait' : ''"
  >
    <i class="icon-plus"></i>
  </button>
</div>
