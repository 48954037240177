import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'masonryGridClass',
})
export class MasonryGridClassPipe implements PipeTransform {
  public transform(index: number): string {
    return `layout-masonry-grid-item--${Math.floor(index / 3) + 1}-${(index % 3) + 1}`;
  }
}
