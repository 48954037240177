import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScannerAdapterAbstract } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ScanNotificationService } from '../scan-notification.service';
import { ScannerComponent } from '../scanner/scanner.component';
import { BreakpointService } from '../../../services/ui/breakpoint.service';

@UntilDestroy()
@Component({
  selector: 'app-scan-button',
  templateUrl: './scan-button.component.html',
  styleUrls: ['./scan-button.component.scss'],
})
export class ScanButtonComponent {
  public isSupported$: Observable<boolean> = combineLatest([this.breakpointService.mobile, this.breakpointService.tablet]).pipe(
    map(([mobile, tablet]: [boolean, boolean]) => mobile || tablet),
    switchMap((correctDevice: boolean) => iif(() => correctDevice, this.scannerAdapter.isSupported(), of(correctDevice))),
    catchError((error: Error) => of(!this.scannerAdapter.handleError(error, false, true))),
  );

  constructor(
    private scannerAdapter: ScannerAdapterAbstract,
    private dialog: MatDialog,
    private scanNotification: ScanNotificationService,
    private breakpointService: BreakpointService,
  ) {}

  public openScanner(): void {
    this.scanNotification.prepare();
    this.scannerAdapter
      .askForPermission()
      .pipe(
        catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((result: boolean) => result),
        untilDestroyed(this),
      )
      .subscribe(() => this.dialog.open(ScannerComponent, { hasBackdrop: true, panelClass: 'scanner-panel' }));
  }
}
