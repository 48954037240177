import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  Breadcrumb,
  BreadcrumbsResolver,
  CmsUiDynamicContentMenu,
  CmsUiDynamicContentMenuLevel,
  CmsUiDynamicContentQuery,
  UrlHelperService,
} from '@lobos/library';

@Injectable({
  providedIn: 'root',
})
export class WeylandPageBreadcrumbResolver extends BreadcrumbsResolver {
  constructor(private cmsQuery: CmsUiDynamicContentQuery, private urlHelper: UrlHelperService) {
    super();
  }

  public override resolve(): Observable<Breadcrumb[]> {
    return this.cmsQuery.cmsMainMenu$.pipe(
      first(),
      map((menu: CmsUiDynamicContentMenu) => this.traverse(menu)),
    );
  }

  private traverse(menu: any, level = 1): Breadcrumb[] {
    const selector = `level_${level}`;
    let matches: Breadcrumb[] = [];

    if (!menu[selector]) {
      return matches;
    }

    const currentLevelItems: CmsUiDynamicContentMenuLevel[] = menu[selector];
    for (const item of currentLevelItems) {
      if (this.pathMatches(item)) {
        return [this.menuItemToBreadcrumb(item)];
      }

      const nextLevelMatches = this.traverse(item as CmsUiDynamicContentMenu, level + 1);
      if (nextLevelMatches.length) {
        matches = [...matches, this.menuItemToBreadcrumb(item), ...nextLevelMatches];
      }
    }

    return matches;
  }

  private menuItemToBreadcrumb(item: CmsUiDynamicContentMenuLevel): {
    label: string;
    translate: boolean;
    url: string;
  } {
    return {
      label: item.title,
      translate: false,
      url: this.urlHelper.localizeUrl(item.slug),
    };
  }

  private pathMatches(item: CmsUiDynamicContentMenuLevel): boolean {
    return this.urlHelper.getPath().substring(3) === item.slug;
  }
}
