import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService, Customer, CustomerService, SalesRepCustomer, SalesRepService, User } from '@lobos/library';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderInfoComponent implements OnInit {
  isOpen = false;
  customer$: Observable<Customer | undefined> | undefined;

  constructor(
    protected authService: AuthService,
    protected salesRepService: SalesRepService<SalesRepCustomer, User>,
    protected customerService: CustomerService<Customer>,
  ) {}

  ngOnInit(): void {
    this.customer$ = this.authService.authUser$.pipe(
      filter((user: User | undefined) => !!user),
      switchMap((user: User | undefined) =>
        user?.sP48SalesRepForUser?.length ? this.customerService.getById(user.lngCustomerID) : of(undefined),
      ),
    );
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  endSalesRepMode($event?: MouseEvent) {
    $event?.stopPropagation();
    this.salesRepService
      .resetSalesRepCustomer()
      .pipe(
        take(1),
        tap(() => window.location.reload()),
      )
      .subscribe();
  }
}
