import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-confirm-tooltip]',
  templateUrl: './confirm-tooltip.component.html',
  styleUrls: ['./confirm-tooltip.component.scss'],
})
export class ConfirmTooltipComponent {
  @Input() entity = '';
  @Output() confirm = new EventEmitter();
}
