import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ArticleFactory, ArticleService, ScannerAdapterAbstract } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ScanNotificationService } from '../scan-notification.service';
import { WeylandSteelArticle } from '../../../interfaces/steel-article.model';

@UntilDestroy()
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit, OnDestroy {
  @ViewChild('preview', { static: true })
  videoContainer: ElementRef<HTMLDivElement> | undefined;

  public ready$: Observable<boolean> = this.scannerAdapter.ready();
  public articles: WeylandSteelArticle[] | undefined;

  private startScan$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private scannerStream$: Observable<string> | undefined;
  private enabled: boolean = true;

  constructor(
    private scannerAdapter: ScannerAdapterAbstract,
    private articleService: ArticleService<WeylandSteelArticle>,
    private scanNotification: ScanNotificationService,
    private dialogRef: MatDialogRef<ScannerComponent>,
    private router: Router,
  ) {
    // when navigation happens while scanner is open
    // -> close the scanner
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.stop());

    this.startScan$
      .pipe(
        filter((start: boolean) => start),
        switchMap(() =>
          this.scannerStream$
            ? this.scannerStream$
            : (this.scannerStream$ = this.scannerAdapter.start(this.videoContainer?.nativeElement as HTMLDivElement)),
        ),
        catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((searchTerm: string | boolean) => !!searchTerm && this.enabled),
        tap(() => this.scanNotification.play()),
        tap(() => this.startScan$.next(false)),
        tap(() => (this.enabled = false)),
        mergeMap((searchTerm: string | boolean) =>
          this.articleService.getArticleByMultiMatchQuery(searchTerm as string, ['sArticleID', 'sEAN']),
        ),
        untilDestroyed(this),
      )
      .subscribe(
        (articles: WeylandSteelArticle[]) =>
          (this.articles = articles.map((article: WeylandSteelArticle) => ArticleFactory.create(article) as WeylandSteelArticle)),
      );
  }

  public ngOnInit(): void {
    if (!this.videoContainer?.nativeElement) {
      return;
    }

    this.startScan$.next(true);
  }

  public rescan(): void {
    this.articles = undefined;
    this.startScan$.next(true);
  }

  public stop(): void {
    this.scannerStream$ = undefined;
    this.scannerAdapter.stop();
    this.startScan$.next(false);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.scannerAdapter.stop();
  }
}
