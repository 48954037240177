import { Injectable } from '@angular/core';
import { CatalogService, CatalogStore, HookEvent, HookHandlerAbstract, HookPriority, LanguageChangedHook } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

type type = LanguageChangedHook;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageChangedCatalogMenuHandler extends HookHandlerAbstract<type, Observable<boolean>> {
  constructor(private catalogService: CatalogService, private catalogStore: CatalogStore) {
    super();
  }

  override priority(): number {
    return HookPriority.HIGH;
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override after(event: HookEvent<type, Observable<boolean>>, result: Observable<boolean>): Observable<boolean> {
    this.catalogStore.reset();

    forkJoin([this.catalogService.getMenuTree()]).pipe(first(), untilDestroyed(this)).subscribe();

    return super.after(event, result);
  }
}
