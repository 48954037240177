<ng-container *transloco="let t; scope: 'shared'">
  <div [tippy]="tpl" variation="popper" #tippy="tippy">
    <ng-content></ng-content>
  </div>

  <ng-template #tpl>
    <div class="flex flex--d-column tippy-custom-container margin--left-m margin--top-m default-text">
      {{ t("shared.ui-confirm-tooltip.confirm-tooltip.delete-msg1") }} {{ entity }}
      {{ t("shared.ui-confirm-tooltip.confirm-tooltip.delete-msg2") }}
      <div class="flex flex--jc-end flex--gap-5 centered margin--top-m margin--bottom-m">
        <button mat-stroked-button (click)="tippy.hide()">
          {{ t("shared.ui-confirm-tooltip.confirm-tooltip.no") }}
        </button>
        <button mat-stroked-button color="warn" (click)="confirm.emit()">
          {{ t("shared.ui-confirm-tooltip.confirm-tooltip.yes") }}
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
