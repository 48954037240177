import { Pipe, PipeTransform } from '@angular/core';
import { ImageGalleryItem, ImageHelperService } from '../../services/catalog/image-helper.service';
import { Article } from '@lobos/library';

@Pipe({
  name: 'articleImages',
})
export class ArticleImagesPipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(article: Article): ImageGalleryItem[] {
    return this.imageHelperService.getArticleImages(article);
  }
}
