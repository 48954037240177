<ng-container *transloco="let t; scope: 'core'">
  <div class="header-search js-header-search" [class.open]="isOpen" [ngClass]="mobileOpen ? 'mobile-open' : ''">
    <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()" class="inline-container">
      <input
        formControlName="searchInput"
        type="text"
        name="q"
        autocomplete="off"
        placeholder="{{ t('core.header-search.searchterm-placeholder') }}"
        class="header-search-input js-header-search-input default-font-sizes inline-middle"
        style="width: 0px"
      />

      <button type="submit" class="header-search-submit js-header-search-submit inline-middle" (click)="mobileOpen = !mobileOpen">
        <i class="icon-magnify"></i>
      </button>
    </form>

    <ng-container *ngIf="isMobile; else notMobile">
      <div class="header-search-result js-header-search-result" [class.open]="isOpen" *ngIf="mobileOpen">
        <div class="header-search-result-category" *ngIf="searchTermHistory$ | async as searchTermHistory">
          <div class="header-search-result-category-title bold default-font-sizes">
            <ng-container *ngIf="searchTermHistory.length > 0"> {{ t("core.header-search.searchterm-history") }}</ng-container>
          </div>

          <div class="header-search-result-category-items" *ngIf="searchTermHistory.length > 0">
            <a
              *ngFor="let searchTerm of searchTermHistory"
              (click)="searchByHistorySearchTerm($event, searchTerm.searchTerm)"
              class="header-search-result-category-item default-font-sizes"
            >
              {{ searchTerm.searchTerm }}
            </a>
          </div>
        </div>
        <ng-container *ngIf="categories$ | async as categories">
          <div *ngIf="categories.length > 0" class="header-search-result-category">
            <div class="header-search-result-category-title bold default-font-sizes">{{ t("core.header-search.categories") }}</div>

            <div class="header-search-result-category-items">
              <a
                *ngFor="let category of categories"
                [routerLink]="category.sUrlPath | urlPath"
                (click)="closeOnClick($event)"
                class="header-search-result-category-item default-font-sizes"
              >
                {{ category.sTitle }}
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="articles$ | async as articles">
          <div *ngIf="articles.length > 0" class="header-search-result-products">
            <div class="header-search-result-products-title bold default-font-sizes">
              {{ t("core.header-search.article") }} <span class="hidden" *ngIf="totalArticles$ | async as total">({{ total }})</span>
            </div>

            <div class="header-search-result-products-items">
              <div class="flex header-search-result-products-item" *ngFor="let article of articles">
                <a
                  [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                  (click)="selectArticle($event, article)"
                  class="header-search-result-products-item-image"
                >
                  <app-image [image]="article | articleMainImage"></app-image>
                </a>

                <div class="flex header-search-result-products-item-info flex--jc-space-between flex--ai-center">
                  <div>
                    <div>
                      <a
                        [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                        (click)="selectArticle($event, article)"
                        class="header-search-result-products-item-text default-font-sizes"
                      >
                        <div class="bold">
                          {{ article.sName }}
                        </div>

                        <div>{{ article.sArticleID }} / {{ article.sUniformNumber }}</div>

                        <div>
                          <app-article-price [article]="article"></app-article-price>
                        </div>
                      </a>
                    </div>
                    <div *ngIf="article.sUrlPath.length > 1" class="search-found-in-groups">
                      <mat-chip-list class="search-chip-list">
                        <mat-chip class="title-chip">{{ t("core.header-search.found-in") }}:</mat-chip>
                        <mat-chip
                          *ngFor="let category of article.oProductInfo"
                          [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                          [queryParams]="{ _productId: category.Id }"
                          (click)="selectArticle($event, article)"
                          >{{ category.sTitle }}</mat-chip
                        >
                      </mat-chip-list>
                    </div>
                  </div>

                  <a
                    class="header-search-result-products-item-cart"
                    [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                    (click)="selectArticle($event, article)"
                  >
                    <i class="icon-cart"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #notMobile>
      <div class="header-search-result js-header-search-result" [class.open]="isOpen">
        <div class="header-search-result-category" *ngIf="searchTermHistory$ | async as searchTermHistory">
          <div class="header-search-result-category-title bold default-font-sizes">
            <ng-container *ngIf="searchTermHistory.length > 0"> {{ t("core.header-search.searchterm-history") }}</ng-container>
          </div>

          <div class="header-search-result-category-items" *ngIf="searchTermHistory.length > 0">
            <a
              *ngFor="let searchTerm of searchTermHistory"
              (click)="searchByHistorySearchTerm($event, searchTerm.searchTerm)"
              class="header-search-result-category-item default-font-sizes"
            >
              {{ searchTerm.searchTerm }}
            </a>
          </div>
        </div>
        <ng-container *ngIf="categories$ | async as categories">
          <div *ngIf="categories.length > 0" class="header-search-result-category">
            <div class="header-search-result-category-title bold default-font-sizes">{{ t("core.header-search.categories") }}</div>

            <div class="header-search-result-category-items">
              <a
                *ngFor="let category of categories"
                [routerLink]="category.sUrlPath | urlPath"
                (click)="closeOnClick($event)"
                class="header-search-result-category-item default-font-sizes"
              >
                {{ category.sTitle }}
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="articles$ | async as articles">
          <div *ngIf="articles.length > 0" class="header-search-result-products">
            <div class="header-search-result-products-title bold default-font-sizes">
              {{ t("core.header-search.article") }} <span class="hidden" *ngIf="totalArticles$ | async as total">({{ total }})</span>
            </div>

            <div class="header-search-result-products-items">
              <div class="flex header-search-result-products-item" *ngFor="let article of articles">
                <a
                  [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                  (click)="selectArticle($event, article)"
                  class="header-search-result-products-item-image"
                >
                  <app-image [image]="article | articleMainImage"></app-image>
                </a>

                <div class="flex header-search-result-products-item-info flex--jc-space-between flex--ai-center">
                  <div>
                    <div>
                      <a
                        [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                        (click)="selectArticle($event, article)"
                        class="header-search-result-products-item-text default-font-sizes"
                      >
                        <div class="bold">
                          {{ article.sName }}
                        </div>

                        <div>{{ article.sArticleID }} / {{ article.sUniformNumber }}</div>

                        <div>
                          <app-article-price [article]="article"></app-article-price>
                        </div>
                      </a>
                    </div>
                    <div *ngIf="article.sUrlPath.length > 1" class="search-found-in-groups">
                      <mat-chip-list class="search-chip-list">
                        <mat-chip class="title-chip">{{ t("core.header-search.found-in") }}:</mat-chip>
                        <mat-chip
                          *ngFor="let category of article.oProductInfo"
                          [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                          [queryParams]="{ _productId: category.Id }"
                          (click)="selectArticle($event, article)"
                          >{{ category.sTitle }}</mat-chip
                        >
                      </mat-chip-list>
                    </div>
                  </div>

                  <a
                    class="header-search-result-products-item-cart"
                    [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
                    (click)="selectArticle($event, article)"
                  >
                    <i class="icon-cart"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-container>
