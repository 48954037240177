import { Injectable } from '@angular/core';
import { AuthLogoutHook, AuthStateEnum, HookEvent, HookHandlerAbstract, HookPriority } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

type type = AuthLogoutHook;

@Injectable({
  providedIn: 'root',
})
export class LogoutShowToastHandler extends HookHandlerAbstract<type, Promise<AuthStateEnum>> {
  static readonly TRANSLOCO_SCOPE = 'shared';

  constructor(private toastr: ToastrService, private transloco: TranslocoService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'AuthLogoutHook' }];
  }

  override priority(): number {
    return HookPriority.HIGH;
  }

  override async after(event: HookEvent<type, Promise<AuthStateEnum>>, result: Promise<AuthStateEnum>): Promise<AuthStateEnum> {
    const message: string = await this.transloco
      .selectTranslate('login-dialog.logout-success', undefined, LogoutShowToastHandler.TRANSLOCO_SCOPE)
      .pipe(first())
      .toPromise();

    const newResult: AuthStateEnum = await super.after(event, result);
    this.toastr.success(message);
    return newResult;
  }
}
