<div class="footer-main default-padding bright-text" *transloco="let t; scope: 'shared'">
  <div class="default-max-width centered">
    <div class="footer-main-logo">
      <img [src]="logoLink" alt="Logo" />
    </div>

    <div class="footer-main-info text-centered default-font-sizes">
      <div class="footer-main-info-text">
        <app-title-and-text [data]="$any(footer.footer_intro)"></app-title-and-text>
      </div>
    </div>

    <div class="footer-main-nav inline-container">
      <div
        class="footer-main-nav-side inline-top default-font-sizes default-font-sizes--big"
        (click)="navTitleStatus1 = !navTitleStatus1"
        [ngClass]="navTitleStatus1 ? 'open' : 'closed'"
      >
        <div class="footer-main-nav-side-title black">
          {{ footer.categories_title }}
        </div>
        <div class="footer-main-nav-side-items">
          <a
            *ngFor="let catalogMenu of catalogMenu$ | async"
            [routerLink]="[getCatalogUrlPath(catalogMenu.sUrlPath)]"
            class="footer-main-nav-side-item footer-main-nav-side-item--has-arrow"
          >
            {{ catalogMenu.sTitle }}
            <i class="icon-arrow-5"></i>
          </a>
        </div>
      </div>

      <div
        class="footer-main-nav-side inline-top bright-text default-font-sizes default-font-sizes--big"
        (click)="navTitleStatus2 = !navTitleStatus2"
        [ngClass]="navTitleStatus2 ? 'open' : 'closed'"
      >
        <div class="footer-main-nav-side-title black">
          {{ footer.cms_title }}
        </div>
        <div class="footer-main-nav-side-items" *transloco="let t">
          <div *ngFor="let cmsMenu of (cmsMenu$ | async)?.level_1">
            <div *ngIf="cmsMenu.specialClass === 'moebelshop'; else notSpecial">
              <a [routerLink]="[getCmsUrlPath(cmsMenu)]" class="footer-main-nav-side-item footer-main-nav-side-item--has-arrow">
                {{ cmsMenu.title }}
                <i class="icon-arrow-5"></i>
              </a>
            </div>

            <ng-template #notSpecial>
              <a [routerLink]="[getCmsUrlPath(cmsMenu)]" class="footer-main-nav-side-item footer-main-nav-side-item--has-arrow">
                {{ cmsMenu.title }}
                <i class="icon-arrow-5"></i>
              </a>
            </ng-template>
          </div>
        </div>
      </div>

      <div
        class="footer-main-nav-side inline-top bright-text default-font-sizes default-font-sizes--big"
        (click)="navTitleStatus3 = !navTitleStatus3"
        [ngClass]="navTitleStatus3 ? 'open' : 'closed'"
      >
        <div class="footer-main-nav-side-title black">
          {{ footer.shop_links_title }}
        </div>
        <div class="footer-main-nav-side-items">
          <a
            *ngFor="let footerShopLinks of footer.shop_links"
            [href]="footerShopLinks.url"
            class="footer-main-nav-side-item footer-main-nav-side-item--has-arrow"
            [target]="footerShopLinks.linkNewTab ? '_blank' : '_self'"
          >
            {{ footerShopLinks.link }}
            <i class="icon-arrow-5"></i>
          </a>
        </div>
      </div>

      <div class="footer-main-nav-side inline-top bright-text default-font-sizes default-font-sizes--big">
        <div *ngFor="let footerShopInfo of footer.shop_info">
          <div class="footer-main-nav-side-title black">
            {{ footerShopInfo?.title }}
          </div>

          <div class="footer-main-nav-side-info default-font-sizes default-font-sizes--small">
            <markdown [data]="footerShopInfo?.text"></markdown>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-main-socials text-centered">
      <div
        class="footer-main-socials-title black default-font-sizes default-font-sizes--big"
        (click)="navTitleStatus4 = !navTitleStatus4"
        [ngClass]="navTitleStatus4 ? 'open' : 'closed'"
      >
        {{ footer.social_title }}
      </div>
      <div class="footer-main-socials-items inline-container">
        <div *ngIf="footer.social_link">
          <a
            *ngFor="let footerSocialLinks of footer.social_link"
            [href]="footerSocialLinks.url"
            class="footer-main-socials-item inline-middle"
            [target]="footerSocialLinks.linkNewTab ? '_blank' : '_self'"
          >
            <img [src]="footerSocialLinks.icon[0].url" alt="Social Logo" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
