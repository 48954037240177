<ng-container *transloco="let t; scope: 'cart'">
  <h2 class="headline-third margin--bottom-m">
    {{ t("cart.ui-cart-type-mismatch-modal.cart-type-mismatch-modal.header") }}
  </h2>

  <ng-container *ngIf="isLoggedIn$ | async; else loggedOut">
    <p class="default-font-sizes margin--bottom-xl">
      {{ t("cart.ui-cart-type-mismatch-modal.cart-type-mismatch-modal.description") }}
    </p>

    <div class="flex flex--jc-space-between flex--wrap flex--gap-5">
      <div class="flex--ai-start">
        <button (click)="create()" class="btn">
          {{ t("cart.ui-cart-type-mismatch-modal.cart-type-mismatch-modal.button.create") }}
        </button>
      </div>
      <div class="flex--ai-end">
        <button (click)="cancel()" class="btn btn--bright">
          {{ t("cart.ui-cart-type-mismatch-modal.cart-type-mismatch-modal.button.cancel") }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #loggedOut>
    <p class="default-font-sizes margin--bottom-xl">
      {{ t("cart.ui-cart-type-mismatch-modal.cart-type-mismatch-modal.description-not-logged-in") }}
    </p>

    <div class="flex flex--jc-space-between flex--wrap flex--gap-5">
      <div class="flex--ai-start">
        <button (click)="openLoginDialog()" class="btn">
          {{ t("cart.ui-cart-type-mismatch-modal.cart-type-mismatch-modal.button.login") }}
        </button>
      </div>
      <div class="flex--ai-end">
        <button (click)="cancel()" class="btn btn--bright">
          {{ t("cart.ui-cart-type-mismatch-modal.cart-type-mismatch-modal.button.cancel") }}
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
