import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SettingsInterface } from '../model/settings.interface';
import { SettingsState, SettingsStore } from '../store/settings.store';

@Injectable({ providedIn: 'root' })
export class SettingsQuery<T extends SettingsInterface> extends Query<SettingsState<T>> {
  constructor(store: SettingsStore<T>) {
    super(store);
  }
}
