import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { NavigationInterface } from '../../interfaces/navigation.interface';

export interface MenuState {
  openMenuItem?: NavigationInterface;
  open: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'menu', resettable: true })
export class MenuStore extends Store<MenuState> {
  constructor() {
    super({ open: false });
  }
}
