<section class="seo block margin--bottom-l margin--top-l">
  <div class="default-max-width centered">
    <h5 *ngIf="data.title" class="default-font-sizes bold default-font-sizes--small margin--bottom-m">
      {{ data.title }}
    </h5>

    <div *ngIf="data.text" class="default-font-sizes default-font-sizes--small">
      <markdown [data]="data.text"></markdown>
    </div>

    <div *ngIf="data.html" [innerHTML]="data.html"></div>
  </div>
</section>
