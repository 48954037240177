import { Injectable } from '@angular/core';
import { HookEvent, HookHandlerAbstract, HookPriority, LaunchedHook } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { CartHelperService } from '../../../services/cart/cart-helper.service';

type type = LaunchedHook;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LaunchedLoadActiveCartHandler extends HookHandlerAbstract<type, Observable<boolean>> {
  constructor(private cartHelper: CartHelperService) {
    super();
  }

  override priority(): number {
    return HookPriority.HIGHEST;
  }

  ids(): type[] {
    return [{ type: 'LaunchedHook' }];
  }

  override after(event: HookEvent<type, Observable<boolean>>, result: Observable<boolean>): Observable<boolean> {
    this.cartHelper.initializeCarts().pipe(untilDestroyed(this)).subscribe();

    return super.after(event, result);
  }
}
