import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CmsUiTitleAndTextContent, FooterIntro } from '@lobos/library';

@Component({
  selector: 'app-title-and-text',
  templateUrl: './title-and-text.component.html',
  styleUrls: ['./title-and-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleAndTextComponent {
  @Input() data!: CmsUiTitleAndTextContent | FooterIntro;
}
