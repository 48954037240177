import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Catalog, CatalogQuery, Parameter, ParameterQuery } from '@lobos/library';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, shareReplay } from 'rxjs/operators';
import { CatalogHelperService } from '../../../../services/catalog/catalog-helper.service';
import { NavigationInterface } from '../../../../interfaces/navigation.interface';

@Component({
  selector: 'app-catalog-menu',
  templateUrl: './catalog-menu.component.html',
  styleUrls: ['./catalog-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogMenuComponent {
  catalogMenu$: Observable<NavigationInterface[]> = combineLatest([this.parameterQuery.params$, this.catalogQuery.selectAll()]).pipe(
    filter(([params, catalog]: [Parameter, Catalog[]]) => !!params && !!catalog.length),
    first(),
    map(([params, catalog]: [Parameter, Catalog[]]) => this.catalogHelperService.buildNavigationFromAll(params, catalog)),
    shareReplay(1),
  );

  constructor(
    private catalogQuery: CatalogQuery,
    private catalogHelperService: CatalogHelperService,
    private parameterQuery: ParameterQuery,
  ) {}
}
