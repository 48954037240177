import { Component, Input } from '@angular/core';
import {
  Catalog,
  CatalogQuery,
  CatalogService,
  CmsUiDynamicContentMenu,
  CmsUiDynamicContentMenuLevel,
  CmsUiDynamicContentQuery,
  Footer,
  Parameter,
  ParameterQuery,
  UrlPathHelperService,
} from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.scss'],
})
export class FooterMainComponent {
  @Input() footer!: Footer;
  public logoLink: string = '/assets/images/logo_' + environment.api.branchKey + '.png';
  catalogMenu$: Observable<Catalog[]> = this.parameterQuery.params$.pipe(
    switchMap((params: Parameter) => {
      return this.catalogQuery.selectCategoriesByParentGroupId$(params.lngProductRootGroup);
    }),
  );
  cmsMenu$: Observable<CmsUiDynamicContentMenu> = this.cmsQuery.select('cmsMainMenu');

  navTitleStatus1 = false;
  navTitleStatus2 = false;
  navTitleStatus3 = false;
  navTitleStatus4 = false;

  constructor(
    private cmsQuery: CmsUiDynamicContentQuery,
    private catalogQuery: CatalogQuery,
    private catalogService: CatalogService,
    private translocoService: TranslocoService,
    private parameterQuery: ParameterQuery,
  ) {}

  public getCmsUrlPath(menu: CmsUiDynamicContentMenuLevel): string {
    return `/${this.translocoService.getActiveLang()}${menu.slug}`;
  }

  public getCatalogUrlPath(sUrlPath: string) {
    return UrlPathHelperService.getUrlPath(sUrlPath, this.translocoService.getActiveLang());
  }
}
