import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NavigationInterface } from '../../../interfaces/navigation.interface';
import { MenuStore } from '../../../services/menu/menu.store';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() navigation!: NavigationInterface;
  @Input() level!: number;

  @Input() classPrefix!: string;

  @Input() parent?: NavigationInterface;

  @Input() isTablet!: boolean;

  constructor(private location: Location, private menuStore: MenuStore) {}

  isActive(item: NavigationInterface): boolean {
    return this.location.path() === item.url;
  }

  isOpen(item: NavigationInterface): boolean {
    if (item.isOpen) {
      return true;
    }

    if (item.subNavigation?.length) {
      let open: boolean = false;

      for (const subNav of item.subNavigation) {
        open = open || subNav.isOpen!;

        if (open) {
          return true;
        }
      }

      return open;
    }

    return false;
  }

  select() {
    this.open();

    this.menuStore.update({ open: false });
  }

  open() {
    if (this.navigation.subNavigation?.length) {
      this.navigation.isOpen = true;
    }

    this.menuStore.update({ openMenuItem: this.navigation });
  }

  back() {
    this.navigation.isOpen = false;
    this.menuStore.update({ openMenuItem: this.parent });
  }
}
