<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="oPrice$ | async as oPrice">
    <ng-container *ngIf="isLoggedIn$ | async; else defaultPrice">
      <ng-container *ngIf="user$ | async as user">
        <ng-container *ngIf="!!user?.oP48APIAuthorizationGroup && user!.oP48APIAuthorizationGroup!.shtPriceLevel > 0; else onRequest">
          <div *ngIf="article?.decSalesPrice1">
            {{ t("shared.ui-article-price.default-price") }}
            <span class="list-price">
              {{ article?.decSalesPrice1! | translocoCurrency }}
              {{
                t("shared.ui-article-price.quantityUnitText", {
                  priceUnit: oPrice.lngSalesPriceUnit,
                  quantityUnit: article?.sQuantityUnitSales ?? oPrice.sQuantityUnit
                })
              }}
            </span>
          </div>
          <div>
            {{ t("shared.ui-article-price.your-price") }}
            <span class="your-price">
              {{ (oPrice.decAmountNetFC ? oPrice.decAmountNetFC : oPrice.decPriceNet) | translocoCurrency }}
              {{
                t("shared.ui-article-price.quantityUnitText", {
                  priceUnit: oPrice.lngSalesPriceUnit,
                  quantityUnit: article?.sQuantityUnitSales ?? oPrice.sQuantityUnit
                })
              }}
            </span>
          </div>
          <div *ngIf="bGrossPrice" class="card-product-pseudo-price default-font-sizes default-font-sizes--very-small slashed">
            <div *ngIf="article?.decSalesPrice1">
              {{ t("shared.ui-article-price.default-price") }}
              <span class="list-price">
                {{ article?.decSalesPrice1! | translocoCurrency }}
                {{
                  t("shared.ui-article-price.quantityUnitText", {
                    priceUnit: oPrice.lngSalesPriceUnit,
                    quantityUnit: article?.sQuantityUnitSales ?? oPrice.sQuantityUnit
                  })
                }}
              </span>
            </div>
            <div>
              {{ t("shared.ui-article-price.your-price") }}
              <span class="your-price">
                {{ oPrice.decAmountGrossFC ? oPrice.decAmountGrossFC : (oPrice.decPriceGross | translocoCurrency) }}
                {{
                  t("shared.ui-article-price.quantityUnitText", {
                    priceUnit: oPrice.lngSalesPriceUnit,
                    quantityUnit: article?.sQuantityUnitSales ?? oPrice.sQuantityUnit
                  })
                }}
              </span>
            </div>
          </div>
        </ng-container>
        <ng-template #onRequest>{{ t("shared.ui-article-price.on-request") }}</ng-template>
      </ng-container>
    </ng-container>
    <ng-template #defaultPrice>
      <ng-container *ngIf="bGrossPrice; else defaultNetPrice">
        <ng-container *ngIf="oPrice.decAmountGrossFC || oPrice.decBasePriceGross; else defaultOnRequest">
          {{ (oPrice.decAmountGrossFC ? oPrice.decAmountGrossFC : oPrice.decBasePriceGross) | translocoCurrency }}
          {{
            t("shared.ui-article-price.quantityUnitText", {
              priceUnit: oPrice.lngSalesPriceUnit,
              quantityUnit: article?.sQuantityUnitSales ?? oPrice.sQuantityUnit
            })
          }}
        </ng-container>
        <ng-template #defaultOnRequest>{{ t("shared.ui-article-price.on-request") }}</ng-template>
      </ng-container>
      <ng-template #defaultNetPrice>
        <ng-container *ngIf="oPrice.decAmountNetFC || oPrice.decBasePriceNet; else defaultNetOnRequest">
          {{ (oPrice.decAmountNetFC ? oPrice.decAmountNetFC : oPrice.decBasePriceNet) | translocoCurrency }}
          {{
            t("shared.ui-article-price.quantityUnitText", {
              priceUnit: oPrice.lngSalesPriceUnit,
              quantityUnit: article?.sQuantityUnitSales ?? oPrice.sQuantityUnit
            })
          }}
        </ng-container>
        <ng-template #defaultNetOnRequest>{{ t("shared.ui-article-price.on-request") }}</ng-template>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>
