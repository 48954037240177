<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, activeFavoriteList: activeFavoriteList$ | async } as context">
    <ng-container *ngIf="activeFavoriteItems$ | async as items">
      <div class="header-dropdown" id="header-dropdown-favourite" tabindex="0">
        <!-- icon with count badge -->
        <div class="header-dropdown-label">
          <div class="icon-wrapper">
            <i class="icon-heart-filled"></i>
            <span class="badge">{{ items.length }}</span>
          </div>
        </div>

        <div class="header-dropdown-content">
          <div class="header-dropdown-content-mobile-header flex mini-cart-item-justify">
            <div>
              <i class="header-dropdown-content-mobile-header-icon icon-heart-filled"></i> {{ t("core.header-nav.favorite-menu.favlist") }}
            </div>
            <div><i class="icon-close"></i></div>
          </div>

          <div class="mini-cart">
            <div class="mini-cart-item mini-cart-select default-font-sizes linked" [hidden]="context.isLoggedIn === false">
              <mat-form-field>
                <select matNativeControl [ngModel]="context.activeFavoriteList" (ngModelChange)="setActiveFavoriteList($event)">
                  <option disabled>{{ t("core.header-nav.favorite-menu.choose-favlist") }}</option>
                  <option class="optionClass" *ngFor="let list of favoriteLists$ | async" [ngValue]="list">
                    {{ t("core.header-nav.favorite-menu.favlist") }}: {{ list.sListname }}
                  </option>
                </select>
              </mat-form-field>
            </div>

            <div *ngIf="isLoggedIn$ | async; else loggedOut">
              <div class="mini-cart" *ngIf="items.length < 1">
                <div class="mini-cart-item default-font-sizes empty-cart-item">
                  {{ t("core.header-nav.favorite-menu.add-to-favlist-message") }}
                </div>
              </div>
            </div>
            <ng-template #loggedOut>
              <div class="mini-cart" *ngIf="items.length < 1">
                <div class="mini-cart-item default-font-sizes">
                  {{ t("core.header-nav.favorite-menu.add-or-login-message") }}
                </div>
              </div>
            </ng-template>

            <div class="mini-cart-item default-font-sizes linked" *ngIf="isLoggedIn$ | async">
              <a [routerLink]="'/profile/favorites' | localizeUrlPath" class="mini-cart-item-justify">
                <span> {{ t("core.header-nav.favorite-menu.manage-favlist") }} </span>

                <i class="icon-setting"></i>
              </a>
            </div>

            <div class="mini-cart-item default-font-sizes linked" *ngIf="isLoggedIn$ | async">
              <a [routerLink]="'/profile/favorites/new/' | localizeUrlPath" class="mini-cart-item-justify">
                <span> {{ t("core.header-nav.favorite-menu.add-favlist") }} </span>

                <i class="icon-plus"></i>
              </a>
            </div>

            <div *ngFor="let item of items" class="mini-cart-item">
              <app-favorite-item [favoriteItem]="item"></app-favorite-item>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- label for icon - below  -->
    <div class="header-nav-item-text">
      <ng-template #notLoggedin>
        <div>{{ t("core.header-nav.favorite-menu.favlist") }}</div>
      </ng-template>

      <div *ngIf="context.isLoggedIn; else notLoggedin">
        <i class="icon-arrow header-nav-item-text-icon header-nav-item-text-icon--first"></i>
        <div
          *ngIf="context.activeFavoriteList"
          class="listname-header-nav"
          [matTooltip]="context.activeFavoriteList.sListname"
          [matTooltipClass]="'tooltip-main'"
          [matTooltipPosition]="'below'"
        >
          {{ context.activeFavoriteList.sListname }}
        </div>
        <i class="icon-arrow header-nav-item-text-icon header-nav-item-text-icon--last"></i>
      </div>
    </div>
  </ng-container>
</ng-container>
