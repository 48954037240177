import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Cart, CartHeader, SteelCartItem, HookEvent, HookHandlerAbstract, switchTap, WorkflowDeleteHook } from '@lobos/library';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

type type = WorkflowDeleteHook<'DeleteCartItemHook'>;

@Injectable({
  providedIn: 'root',
})
export class CartDeleteCartItemHandler extends HookHandlerAbstract<type, Observable<Cart<CartHeader, SteelCartItem>>> {
  private static readonly TRANSLOCO_SCOPE = 'cart';

  constructor(private toastr: ToastrService, private translocoService: TranslocoService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowDeleteHook', entity: 'DeleteCartItemHook' }];
  }

  override after(
    event: HookEvent<type, Observable<Cart<CartHeader, SteelCartItem>>>,
    result: Observable<Cart<CartHeader, SteelCartItem>>,
  ): Observable<Cart<CartHeader, SteelCartItem>> {
    return super.after(event, result).pipe(
      switchTap((_: Cart<CartHeader, SteelCartItem>) => {
        return this.translocoService
          .selectTranslate('cart-service.toast-success-remove', undefined, CartDeleteCartItemHandler.TRANSLOCO_SCOPE)
          .pipe(
            first(),
            tap((message: string) => this.toastr.success(message)),
          );
      }),
    );
  }
}
