<ng-container *ngIf="navigation">
  <ng-container *ngIf="navigation.subNavigation?.length">
    <ng-container *ngIf="isTablet">
      <a (click)="open()" [class.active]="isActive(navigation)" class="{{ classPrefix }}-item-title">
        <ng-content></ng-content>

        <div class="default-font-sizes default-font-sizes--big">
          {{ navigation.title }}
        </div>

        <button class="{{ classPrefix }}-item-open">
          <i class="icon-arrow-2"></i>
        </button>
      </a>
    </ng-container>

    <ng-container *ngIf="!isTablet">
      <div class="{{ classPrefix }}-item-title">
        <ng-content></ng-content>

        <ng-container *transloco="let t; scope: 'shared'">
          <div *ngIf="navigation.specialClass === 'moebelshop'; else notSpecial">
            <a
              (click)="select()"
              [routerLink]="navigation.url"
              [class.active]="isActive(navigation)"
              class="{{ classPrefix }}-item-title {{ navigation.specialClass }}"
            >
              <div class="default-font-sizes default-font-sizes--big moebelLink">
                {{ navigation.title }}
              </div>
            </a>
          </div>
        </ng-container>

        <ng-template #notSpecial>
          <a (click)="select()" [routerLink]="navigation.url" [class.active]="isActive(navigation)" class="{{ classPrefix }}-item-title">
            <div class="default-font-sizes default-font-sizes--big">
              {{ navigation.title }}
            </div>
          </a>
        </ng-template>

        <button class="{{ classPrefix }}-item-open">
          <i class="icon-arrow-2"></i>
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!navigation.subNavigation?.length">
    <ng-container *transloco="let t; scope: 'shared'">
      <div *ngIf="navigation.specialClass === 'moebelshop'; else notSpecial">
        <a
          (click)="select()"
          [href]="t('shared.moebelshop-link')"
          target="_blank"
          [class.active]="isActive(navigation)"
          class="{{ classPrefix }}-item-title {{ navigation.specialClass }}"
        >
          <div class="specialElementTitle default-font-sizes default-font-sizes--big">
            {{ navigation.title }}
          </div>
        </a>
      </div>
    </ng-container>

    <ng-template #notSpecial>
      <ng-container *ngIf="navigation.directlink; else noDirectLink">
        <a
          (click)="select()"
          [href]="navigation.directlink ? navigation.directlink : navigation.url"
          target="_blank"
          [class.active]="isActive(navigation)"
          class="{{ classPrefix }}-item-title"
        >
          <div class="default-font-sizes default-font-sizes--big">{{ navigation.title }}</div>
        </a>
      </ng-container>

      <ng-template #noDirectLink>
        <a
          (click)="select()"
          [routerLink]="navigation.directlink ? navigation.directlink : navigation.url"
          [class.active]="isActive(navigation)"
          class="{{ classPrefix }}-item-title"
        >
          <div class="default-font-sizes default-font-sizes--big">{{ navigation.title }}</div>
        </a>
      </ng-template>
    </ng-template>
  </ng-container>

  <div class="{{ classPrefix }}-items" *transloco="let t; scope: 'shared'" [attr.data-depth]="level">
    <ul [attr.data-depth]="level">
      <li *ngIf="parent" class="{{ classPrefix }}-item-back">
        <button (click)="back()" class="default-font-sizes default-font-sizes--big">
          {{ t("shared.ui-menu-item.button.back") }}
        </button>
      </li>

      <ng-container *ngIf="navigation.subNavigation?.length">
        <li class="{{ classPrefix }}-item-discover-all" [attr.data-depth]="level">
          <a [routerLink]="navigation.url" (click)="select()" [class.active]="isActive(navigation)" class="{{ classPrefix }}-item-title">
            <div class="default-font-sizes default-font-sizes--big">
              {{ navigation.title }}
            </div>
          </a>
        </li>

        <li class="{{ classPrefix }}-item-discover-all" [attr.data-depth]="level">
          <a [routerLink]="navigation.url" (click)="select()" [class.active]="isActive(navigation)" class="{{ classPrefix }}-item-title">
            <div class="default-font-sizes default-font-sizes--big">
              {{ t("shared.ui-menu-item.button.discover-all") }}
            </div>
          </a>
        </li>

        <li [attr.data-depth]="level" *ngFor="let subNavigation of navigation.subNavigation">
          <app-menu-item
            class="{{ classPrefix }}-item"
            [class.open]="isOpen(subNavigation)"
            [isTablet]="isTablet"
            [parent]="navigation"
            [navigation]="subNavigation"
            [classPrefix]="classPrefix"
            [level]="level + 1"
            [attr.data-depth]="level"
          ></app-menu-item>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>
