import { environmentCommon } from './environment.common';

export const environment = {
  ...environmentCommon,
  loadTranslationFromServer: true,
  production: true, // TODO change after fixing camelCase in request headers
  debugging: false,
  host: 'https://shop.weyland.at',
  ssrPort: 4203,

  api: {
    ...environmentCommon.api,
    host: 'https://shop.weyland.at',
  },

  cms: {
    ...environmentCommon.cms,
    host: 'http://localhost:1340',
  },

  oauth2: {
    adminBasePath: 'https://shop.weyland.at/hydra-admin',
  },

  sentry: { ...environmentCommon.sentry, active: true },
  name: 'PRODUCTION',

  tracking: {
    gtm: 'GTM-KM3WZCQ',
  },
};
