import { Injectable } from '@angular/core';
import { Article, CartItem, Price, PriceService, SalesCreditNoteItem, SalesOfferItem, SalesOrderItem } from '@lobos/library';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArticleHelperService {
  constructor(private priceService: PriceService) {}

  public itemIsMaster(item: CartItem | SalesOrderItem | SalesOfferItem | SalesCreditNoteItem): boolean {
    if (item.shtFixedItemIDItemGroup) {
      return item.shtFixedItemIDItemGroup === 0 || item.shtFixedItemIDItemGroup === item.shtFixedItemID;
    } else {
      return true;
    }
  }

  public itemIsChildOf(
    child: CartItem | SalesOrderItem | SalesOfferItem | SalesCreditNoteItem,
    master: CartItem | SalesOrderItem | SalesOfferItem | SalesCreditNoteItem,
  ): boolean {
    if (child.shtFixedItemIDItemGroup) {
      return (
        child.shtFixedItemIDItemGroup !== 0 &&
        child.shtFixedItemIDItemGroup === master.shtFixedItemIDItemGroup &&
        child.shtFixedItemIDItemGroup !== child.shtFixedItemID
      );
    } else {
      return false;
    }
  }

  public getPriceFromApi(article: Article): Observable<Price | undefined> {
    return this.priceService.get(
      article.sArticleID,
      article.sQuantityUnitSales,
      'CHF',
      article.decQty > 0 ? article.decQty : 1,
      article.decFactor1,
      article.decFactor2,
      article.decFactor3,
    );
  }
}
