import { Component, Inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DateAdapter } from '@angular/material/core';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private translocoService: TranslocoService,
    private translocoLocalService: TranslocoLocaleService,
    private dateAdapter: DateAdapter<any>,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    // Set the default locale
    this.translocoLocalService.setLocale(environment.defaultLocale);
    this.dateAdapter.setLocale(environment.defaultLocale);
    this._document.getElementById('appFavicon')!.setAttribute('href', `/assets/images/favicon/favicon_${environment.api.branchKey}.ico`);
  }
}
