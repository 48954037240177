import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { HookHandlerFactoryService, InjectorClass } from '@lobos/library';
import { AuthorizationChangedResetPricesHandler } from './handler/authorization-changed-reset-prices.handler';
import { AuthorizationChangedUpdateSentryHandler } from './handler/authorization-changed-update-sentry.handler';
import { AuthorizedActivateCartHandler } from './handler/authorized-activate-cart.handler';
import { AuthorizedActivateFavoriteListHandler } from './handler/authorized-activate-favorite-list.handler';
import { AuthorizedConvertLocalCartHandler } from './handler/authorized-convert-local-cart.handler';
import { AuthorizedConvertLocalFavoritesHandler } from './handler/authorized-convert-local-favorites.handler';
import { ErrorLogToConsoleHandler } from './handler/error-log-to-console.handler';
import { ErrorSendToSentryHandler } from './handler/error-send-to-sentry.handler';
import { ErrorSendToTrackingHandler } from './handler/error-send-to-tracking.handler';
import { LanguageChangedChangeElasticSearchIndexHandler } from './handler/language-changed-change-elasticsearch-index.handler';
import { LanguageChangedCmsStoresHandler } from './handler/language-changed-cms-stores.handler';
import { LaunchedLoadActiveCartHandler } from './handler/launched-load-active-cart.handler';
import { LaunchedLoadActiveFavoritesHandler } from './handler/launched-load-active-favorites.handler';
import { LanguageChangedCatalogMenuHandler } from './handler/language-changed-catalog-menu.handler';
import { CartCreateCartItemHandler } from './handler/cart-create-item.handler';
import { CartDeleteCartItemHandler } from './handler/cart-delete-item.handler';
import { OrderCreateOrderFromOfferHandler } from './handler/order-create-order-from-offer.handler';
import { FavoriteDeleteItemHandler } from './handler/favorite-delete-item.handler';
import { FavoriteCreateItemHandler } from './handler/favorite-create-item.handler';
import { CartCreateCartFromOrderHandler } from './handler/cart-create-cart-from-order.handler';
import { LogoutShowToastHandler } from './handler/logout-show-toast.handler';
import { LoginShowToastHandler } from './handler/login-show-toast.handler';
import { ErrorShowToastHandler } from './handler/error-show-toast.handler';
import { CartTypeMismatchModal } from './handler/cart-type-mismatch-modal.handler';
import { CartDeleteCartItemResetCartTypeHandler } from './handler/cart-delete-item-reset-cart-type.handler';

const hookHandlers: any[] = [
  LanguageChangedCatalogMenuHandler,
  LaunchedLoadActiveCartHandler,
  LaunchedLoadActiveFavoritesHandler,
  ErrorLogToConsoleHandler,
  ErrorShowToastHandler,
  ErrorSendToSentryHandler,
  LanguageChangedCmsStoresHandler,
  LanguageChangedChangeElasticSearchIndexHandler,
  ErrorSendToTrackingHandler,
  AuthorizationChangedUpdateSentryHandler,
  AuthorizedConvertLocalCartHandler,
  AuthorizedConvertLocalFavoritesHandler,
  AuthorizedActivateCartHandler,
  AuthorizedActivateFavoriteListHandler,
  AuthorizationChangedResetPricesHandler,
  FavoriteCreateItemHandler,
  FavoriteDeleteItemHandler,
  CartCreateCartItemHandler,
  CartDeleteCartItemHandler,
  CartCreateCartFromOrderHandler,
  OrderCreateOrderFromOfferHandler,
  LoginShowToastHandler,
  LogoutShowToastHandler,
  CartTypeMismatchModal,
  CartDeleteCartItemResetCartTypeHandler,
];

@NgModule({
  imports: [CommonModule],
  providers: [],
})
export class HookHandlerModule {
  constructor(injector: Injector, hookHandlerFactory: HookHandlerFactoryService) {
    InjectorClass.set(injector);

    for (const hookHandler of hookHandlers) {
      hookHandlerFactory.register(injector.get<any>(hookHandler));
    }
  }

  static forRoot(): ModuleWithProviders<HookHandlerModule> {
    return {
      ngModule: HookHandlerModule,
      providers: [],
    };
  }
}
