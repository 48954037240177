<div class="default-padding" [ngStyle]="{ 'background-image': 'url(' + headerBgLink + ')' }">
  <div class="header-main default-max-width centered">
    <button class="header-main-opener js-header-main-opener" (click)="toggelMenu.emit()">
      <div></div>
      <div></div>
      <div></div>
    </button>

    <a [routerLink]="'/' | localizeUrlPath" class="header-main-logo">
      <img [src]="logoLink" alt="Logo" />
    </a>

    <app-header-search *appIsBrowser></app-header-search>

    <div class="mobile-hidden tablet-hidden">
      <app-header-nav *appIsBrowser class="header-nav mobile-hidden"></app-header-nav>
    </div>
  </div>
</div>
