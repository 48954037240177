import { Injectable } from '@angular/core';
import { Template } from '@lobos/label-print';
import { Weyland3x8QrRenderer } from './weyland_3x8_qr.renderer';

@Injectable({
  providedIn: 'root',
})
export class Weyland3x8BorderQrRenderer extends Weyland3x8QrRenderer {
  public override template(): Template {
    return {
      id: 'default',
      title: 'profile.label-print.template.weyland3x8_border_qr.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 64.6, height: 33.8 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 8 },
    };
  }
}
