import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MenuState, MenuStore } from './menu.store';

@Injectable({
  providedIn: 'root',
})
export class MenuQuery extends Query<MenuState> {
  isOpen$ = this.select('open');
  activeMenuItem$ = this.select('openMenuItem');

  constructor(protected override store: MenuStore) {
    super(store);
  }
}
