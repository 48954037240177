<ng-container *transloco="let t; scope: 'shared'">
  <div class="breadcrumb default-padding" *ngIf="crumbs$ | async as crumbs">
    <div class="breadcrumb-inner default-max-width centered inline-container">
      <a (click)="back()" class="breadcrumb-item breadcrumb-item--back inline-middle default-font-sizes default-font-sizes--small">
        <i class="icon-arrow-5"></i>
        <!-- TODO// Translate-->
        {{ t("shared.breadcrumb.back") }}
      </a>
      <ng-container *ngFor="let crumb of crumbs; let last = last">
        <a
          *ngIf="!last"
          [routerLink]="crumb.url | urlEncoding"
          class="breadcrumb-item inline-middle default-font-sizes default-font-sizes--small"
        >
          {{ crumb.label }}
        </a>
        <div *ngIf="last" class="breadcrumb-item active inline-middle default-font-sizes default-font-sizes--small">
          {{ crumb.label }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
