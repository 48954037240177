<div class="{{ classPrefix }}-root-item" [attr.data-depth]="rootLevel">
  <app-menu-item
    [isTablet]="isTablet"
    class="{{ classPrefix }}-item"
    [class.open]="navigation.isOpen"
    *ngFor="let navigation of menu"
    [navigation]="navigation"
    [parent]="$any({})"
    [classPrefix]="classPrefix"
    [attr.data-depth]="rootLevel"
    [level]="rootLevel + 1"
  >
    <ng-content></ng-content>
  </app-menu-item>
</div>
