<div class="header-nav">
  <span class="header-nav-item inline-middle default-font-sizes default-font-sizes--small">
    <app-scan-button></app-scan-button>
  </span>

  <span class="header-nav-item inline-middle default-font-sizes default-font-sizes--small">
    <app-favorite-menu></app-favorite-menu>
  </span>

  <span class="header-nav-item inline-middle default-font-sizes default-font-sizes--small">
    <app-cart-menu></app-cart-menu>
  </span>

  <span class="header-nav-item inline-middle default-font-sizes default-font-sizes--small">
    <app-profile-menu></app-profile-menu>
  </span>
</div>
