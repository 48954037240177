import { Injectable } from '@angular/core';
import { ErrorHook, ErrorInterface, HookEvent, HookHandlerAbstract, LogService } from '@lobos/library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type type = ErrorHook;

@Injectable({
  providedIn: 'root',
})
export class ErrorLogToConsoleHandler extends HookHandlerAbstract<type, Observable<ErrorInterface>> {
  constructor(private logService: LogService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'ErrorHook' }];
  }

  override after(event: HookEvent<type, Observable<ErrorInterface>>, result: Observable<ErrorInterface>): Observable<ErrorInterface> {
    return super.after(event, result).pipe(tap((error: ErrorInterface) => this.logService.error(error.label, error)));
  }
}
