<ng-container *transloco="let t; scope: 'shared'">
  <div class="forgot-password-container" *ngIf="successMessage === false; else passwordRequestSuccess">
    <div class="default-font-sizes default-font-sizes--small default-text-spacing margin--bottom-l">
      <h1 class="headline-second margin--top-l">
        {{ t("shared.forgot-password.forgot-password-title") }}
      </h1>
      <p>
        {{ t("shared.forgot-password.forgot-password-intro") }}
      </p>
    </div>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitPasswordForgot()">
      <mat-form-field class="margin--top-m" appearance="fill">
        <mat-label>{{ t("shared.forgot-password.email") }}</mat-label>
        <input matInput formControlName="email" required />
      </mat-form-field>
      <button type="submit" [disabled]="forgotPasswordForm.invalid" class="btn margin--top-m">
        {{ t("shared.forgot-password.reset-password") }}
      </button>
    </form>
  </div>
  <ng-template #passwordRequestSuccess>
    <div class="forgot-password-container">
      <div class="default-font-sizes default-font-sizes--small default-text-spacing margin--bottom-l">
        <h1 class="headline-second margin--top-l">
          {{ t("shared.forgot-password.forgot-password-success-title") }}
        </h1>
        <p>
          {{ t("shared.forgot-password.forgot-password-success-intro") }}
        </p>
      </div>
    </div>
  </ng-template>
</ng-container>
